export default {
  core: {
    session: {
      list: "/sessions",
      allFeedback: "/session/feedback",
      feedbackById: (feedbackId) => `/session/feedback/${feedbackId}`,
      attachmentById: (feedbackId) => `/session/attachment/${feedbackId}`,
      allActivities: "/session/activities",
      allApps: "/session/applications",
      allDevices: "/session/devices",
      allUsers: "/session/users",
      notifyWhenAvailable: "/notify-when-available",
    },
    app: {
      categories: "/app/categories",
      getCommentsByAppId: (appId) => `/app/${appId}/comment`,
      getCommentById: (appId, commentId) => `app/${appId}/comment/${commentId}`,
      getCommentAttachment: (appId, commentId) =>
        `app/${appId}/comment/${commentId}/attachments`,
      commentAttachmentById: (appId, commentId, attachmentId) =>
        `/app/${appId}/comment/${commentId}/attachment/${attachmentId}`,
      getInstallList: (appId) => `/app/${appId}/installs`,
      getShareLinkList: (appId) => `/app/${appId}/share`,
    },
    releases: {
      list: (appId) => `/app/${appId}/releases`,
      detail: (appId, releaseId) => `/app/${appId}/release/${releaseId}`,
      notify: (appId, releaseId) => `/app/${appId}/release/${releaseId}/notify`,
    },
  },
  portal: {
    app: {
      list: `/apps`,
      details: (appId) => `/app/${appId}`,
      getDashboardData: (appId) => `/app/${appId}/dashboard`,
      stats: (appId) => `/app/${appId}/stats`,
      export: (appId) => `/app/${appId}/export`,
      settings: (appId) => `/app/${appId}/install/settings`,
      screenshots: (appId) => `/app/${appId}/screenshots`,
    },
    dashboard: {
      stats: "/dashboard/stats",
    },
    integrations: {
      list: "/team/integrations",
      detail: (integrationID) => `/team/integration/${integrationID}`,
      check: `/team/integrations/check`,
      slack: {
        auth: `/team/integrations/slack/auth`,
        customSearch: {
          channels: (integrationID, sourceID, action) =>
            `/team/integration/${integrationID}/source/${sourceID}/custom-search/${action}`,
        },
      },
      jira: {
        customSearch: {
          projects: (integrationID, sourceID, action) =>
            `/team/integration/${integrationID}/source/${sourceID}/custom-search/${action}`,
        },
      },
      source: {
        generateAuth: (integrationID) =>
          `/team/integration/${integrationID}/auth`,
        connect: {
          slack: (integrationID) => `team/integration/${integrationID}`,
          microsoftTeam: (integrationID) => `team/integration/${integrationID}`,
          jira: (integrationID) => `team/integration/${integrationID}`,
        },
        sourceList: (integrationID) =>
          `/team/integration/${integrationID}/sources`,
        sourceDetail: (integrationID, sourceID) =>
          `/team/integration/${integrationID}/source/${sourceID}`,
        disconnect: (integrationID, sourceID) =>
          `/team/integration/${integrationID}/source/${sourceID}`,
        update: (integrationID, sourceID) =>
          `/team/integration/${integrationID}/source/${sourceID}`,
      },
      sourceSub: {
        create: {
          slack: (integrationID, sourceID) =>
            `/team/integration/${integrationID}/source/${sourceID}/sub`,
          jira: (integrationID, sourceID) =>
            `/team/integration/${integrationID}/source/${sourceID}/sub`,
        },
        teamDefault: (integrationID, sourceID, subID) =>
          `/team/integration/${integrationID}/source/${sourceID}/sub/${subID}/team-default`,
        subList: (integrationID, sourceID) =>
          `/team/integration/${integrationID}/source/${sourceID}/subs`,
        delete: (integrationID, sourceID, subID) =>
          `/team/integration/${integrationID}/source/${sourceID}/sub/${subID}`,
        update: (integrationID, sourceID, subID) =>
          `/team/integration/${integrationID}/source/${sourceID}/sub/${subID}`,
      },
      sourceSubApp: {
        addApp: (integrationID, sourceID, subID) =>
          `/team/integration/${integrationID}/source/${sourceID}/sub/${subID}/app`,
        appList: (integrationID, sourceID, subID) =>
          `/team/integration/${integrationID}/source/${sourceID}/sub/${subID}/apps`,
        delete: (integrationID, sourceID, subID) =>
          `/team/integration/${integrationID}/source/${sourceID}/sub/${subID}/app`,
        appSubList: (appID) => `/app/${appID}/integrations`,
      },
      list: "/team/integrations",
      service: {
        list: `/team/integrations/slack`,
        enable: (id, name) => `/team/integrations/${name}/${id}/enable`,
        disable: (id) => `/team/integrations/slack/${id}/disable`,
        uninstall: (id) => `/team/integrations/slack/${id}`,
        install: (id) => `/team/integrations/slack/${id}/enable`,
      },
      project: {
        list: `/team/integrations/slack/channels`,
        create: (id) => `/team/integrations/slack/${id}/channel`,
        update: (id, channelID) =>
          `/team/integrations/slack/${id}/channel/${channelID}`,
        addApp: (id, channelID, appID) =>
          `/team/integrations/slack/${id}/channel/${channelID}/app/${appID}`,
        removeApp: (id, channelID, appID) =>
          `/team/integrations/slack/${id}/channel/${channelID}/app/${appID}`,
        disable: (id, channelID) =>
          `/team/integrations/slack/${id}/channel/${channelID}/disable`,
        delete: (id, channelID) =>
          `/team/integrations/slack/${id}/channel/${channelID}/delete`,
      },
    },
  },
};
