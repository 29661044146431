import ApiService from "@/core/services/api.service";
// action types
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const GET_MEMBERS = "checkMembers";
export const CHECK_MEMBER_LIMIT = "checkMemberLimit";
export const REMOVE_MEMBER = "removeMembers";
export const SET_ALL_MEMBERS = "setAllMembers";
export const CLEAR_TEAM_MEMBER_DATA = "clearTeamMemberData";
export const DISABLE_ENABLE_MEMBERS = "disableEnableMembers";
export const TRANSFER_OWNERSHIP = "transferOwnership";
export const DOWNLOAD_ALL_UDID = "downloadAllUDID";
export const DOWNLOAD_MEMBER_UDID = "downloadMemberUDID";

const state = {
  allMembers: [],
  totalMembersCount: 0,
  totalMembersData: [],
};

const getters = {
  getAllMembers(state) {
    return state.allMembers;
  },
  getTotalMembersData(state) {
    return state.totalMembersData;
  },
  getTotalMembersCount(state) {
    return state.totalMembersCount;
  },
};

const actions = {
  [GET_MEMBERS](context, lastID) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/members?lastID=${lastID || ""}`)
        .then(({ data }) => {
          if (data.results && data.results.data) {
            if (!lastID) {
              context.commit(SET_ALL_MEMBERS, data.results.data);
              context.commit(
                "setTotalMembersCount",
                data.results && data.results.meta ? data.results.meta.count : ""
              );
            } else {
              context.commit("setMembersPaginatedData", data.results.data);
            }
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          // it settign no data just stoping the loader
          context.commit(SET_ALL_MEMBERS);
        });
    });
  },
  [UPDATE_MEMBER](context, updatedData) {
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.put(`core/team/member/${updatedData.id}`, updatedData.data)
        .then(({ data }) => {
          context.commit("notifyUserMessage", {
            message: data.message || data,
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("notifyErrorMessage", error.message || error);
        });
    });
  },
  [CHECK_MEMBER_LIMIT]() {
    return new Promise((resolve, reject) => {
      ApiService.post(`core/team/members/check`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRANSFER_OWNERSHIP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`core/team/transfer-ownership`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DOWNLOAD_ALL_UDID](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/member/devices/download-udids`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DOWNLOAD_MEMBER_UDID](context, memberID) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/member/${memberID}/devices/download-udids`)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DISABLE_ENABLE_MEMBERS](context, payload) {
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.post(`core/team/member/${payload.id}/status`, payload)
        .then(({ data }) => {
          context.commit("notifyUserMessage", data.message || data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("notifyErrorMessage", error.message || error);
        });
    });
  },
  [REMOVE_MEMBER](context, id) {
    // reset the messages
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.delete(`core/team/member/${id}`)
        .then(({ data }) => {
          context.commit("notifyUserMessage", data.message || data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("notifyErrorMessage", error);
        });
    });
  },
};

const mutations = {
  [SET_ALL_MEMBERS](state, data) {
    // place each data to there places
    if (data) {
      state.totalMembersData = data;
      state.allMembers = data;
    }
    state.memberInvitesLoader = false;
  },
  removeMemberFromList(state, payload) {
    state.allMembers = state.allMembers.filter((x) => x.id !== payload.id);
    state.totalMembersData = state.totalMembersData.filter(
      (x) => x.id !== payload.id
    );
    if (payload.status == 1) {
      state.totalMembersCount = state.totalMembersCount
        ? state.totalMembersCount - 1
        : state.totalMembersCount;
    }
  },
  setAppMemberList(state, payload) {
    state.allMembers = state.allMembers.map((member) => {
      if (member.id === payload.id) {
        member = payload;
      }
      return member;
    });
  },
  updateMemberAppStats(state, payload) {
    state.allMembers = state.allMembers.map((member) => {
      if (member.id === payload.memberID) {
        member.stats.apps = member.stats.apps + (payload.count || 1);
      }
      return member;
    });
  },
  removeMemberAppStats(state, payload) {
    state.allMembers = state.allMembers.map((member) => {
      if (member.id === payload.memberID) {
        member.stats.apps = member.stats.apps - (payload.count || 1);
      }
      return member;
    });
  },
  updateAllMemebers(state, payload) {
    state.allMembers.splice(payload.index, 1, payload.dataToAppend);
    state.totalMembersData.splice(payload.index, 1, payload.dataToAppend);
  },
  setTotalMembersCount(state, payload) {
    state.totalMembersCount = payload;
  },
  setMembersPaginatedData(state, data) {
    let members = state.totalMembersData.length
      ? state.totalMembersData
      : state.allMembers;
    members = [...members, ...data];
    state.allMembers = [...data];
    state.totalMembersData = [...members];
  },
  setPreviousMembersData(state, data) {
    state.allMembers = [...data];
  },
  [CLEAR_TEAM_MEMBER_DATA](state) {
    state.allMembers = [];
    state.totalMembersCount = 0;
    state.totalMembersData = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
