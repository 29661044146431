<template>
  <v-app class="background">
    <v-theme-provider root>
      <router-view></router-view>
    </v-theme-provider>
    <vue-snack-bar></vue-snack-bar>
    <snackbar />
    <modal
      v-model="showReleaseUpdate"
      :hide-close="
        getPortalUpdateInfo &&
        getPortalUpdateInfo.versionInfo &&
        getPortalUpdateInfo.versionInfo.force
      "
      @close="showReleaseUpdate = false"
    >
      <template #message>
        <v-row class="py-4">
          <v-col class="bold-text pb-0 font-size-h3" cols="12">
            New release ready
          </v-col>
          <v-col class="font-size-h4" cols="12">
            <div class="mt-3">
              New version
              <b>{{
                `${
                  getPortalUpdateInfo && getPortalUpdateInfo.versionInfo
                    ? getPortalUpdateInfo.versionInfo.version
                    : ""
                }`
              }}</b>
              (released
              <vue-hoverable-date
                v-if="getPortalUpdateInfo && getPortalUpdateInfo.versionInfo"
                :date="
                  getPortalUpdateInfo.versionInfo.date ||
                  getPortalUpdateInfo.versionInfo.update_time
                "
              ></vue-hoverable-date>
              )
            </div>
            <div class="my-4">
              You're using
              <b> {{ `${currentVersion.split("@")[1]}` }}</b> (released
              <vue-hoverable-date
                :date="currentReleaseTimestamp"
              ></vue-hoverable-date>
              )
            </div>
            <div class="mb-3">
              We're ready to load new version, and will bring you right back to
              this page in few seconds.
            </div>
          </v-col>
          <v-col class="text-right pb-0" cols="12">
            <v-btn
              v-if="getPortalUpdateInfo && getPortalUpdateInfo.versionInfo"
              class="text-transform-none mx-3"
              color="primary"
              depressed
              @click="refresh"
            >
              {{
                getPortalUpdateInfo.versionInfo.force
                  ? "Reload to update"
                  : "I'm Ready!"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </modal>
    <Modal v-model="showSwitchModal">
      <template #card>
        <v-card class="pa-3">
          <v-row>
            <v-col class="my-4 font-size-h3 bold-text" cols="12">
              Switching teams
            </v-col>
            <v-col class="text-center" cols="12">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
            <v-col class="text-center" cols="12">Please wait...</v-col>
          </v-row>
        </v-card>
      </template>
    </Modal>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "app",
  data() {
    return {
      tab: null,
      versionData: null,
      showSwitchModal: false,
      showReleaseUpdate: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamId: "getTeamId",
      getTeamName: "getTeamName",
      getSwitchModal: "getSwitchModal",
      getDarkTheme: "getDarkTheme",
      isSocketDisconnected: "getIsSocketDisconnected",
      isUploadingProgress: "getIsUploadingReleases",
      getPortalUpdateInfo: "getPortalUpdateInfo",
    }),
    currentVersion() {
      return process.env.VUE_APP_RELEASE;
    },
    currentVersionCode() {
      return process.env.VUE_APP_RELEASE_CODE;
    },
    currentReleaseTimestamp() {
      return process.env.VUE_APP_RELEASE_DATE;
    },
  },
  watch: {
    getSwitchModal(val) {
      if (val) {
        this.showSwitchModal = true;
      }
    },
    getPortalUpdateInfo(val) {
      this.showReleaseUpdate = val.showReleaseUpdate;
    },
  },
  mounted() {
    let self = this;
    window.onbeforeunload = () => {
      if (self.isUploadingProgress) {
        return "If you reload this page, your upload queue and file will be interrupted";
      }
    };
    const unUsedLocalstorageItems = [
      "remember_show_install_popup",
      "express_welcom_modal",
      "remember_box",
      "express_upload_tour",
      "isUploadingReleases",
    ];
    unUsedLocalstorageItems.forEach((item) => {
      window.localStorage.removeItem(item);
    });
  },
  methods: {
    ...mapMutations({
      setIsSocketDisconnected: "setIsSocketDisconnected",
      setDataFromSocket: "setDataFromSocket",
      setSwitchModal: "setSwitchModal",
    }),
    refresh() {
      this.showReleaseUpdate = false;
      window.location.reload();
      this.showReleaseUpdate = false;
    },
  },
};
</script>

<style lang="scss">
@import "assets/fonts/roboto/roboto.css";

// Main demo style scss
@import "assets/sass/style.vue";

.test-app {
  background-color: #f6f7f9 !important;
}

.container {
  max-width: 1340px !important;
}
</style>
