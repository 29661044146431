import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#396BDB",
        secondary: "#396BDB",
        accent: "#58B9F0",
        error: "#e75146",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822",
        darkOrange: "#A47125",
        lightRed: "#FF9C9C",
        grey: "#dadae1",
        darkGrey: "#464e5f",
        background: "#f1f5fc",
        offWhite: "#ffffff",
        iconColor: "#ffffff",
      },
      dark: {
        primary: "#adbac7",
        secondary: "#22272e",
        info: "#5578eb",
        grey: "#f1f5fc",
        iconColor: "#adbac7",
        background: "#22272e",
        darkGrey: "#adbac7",
        success: "#0abb87",
        warning: "#ffb822",
        offWhite: "#464e5f",
      },
    },
  },
});
