export default {
  state: {
    redirectedFromPlan: null,
    isNetworkError: false,
    snackbarMessage: {
      show: false,
      color: "green",
      message: "",
      timeout: false,
    },
    socketMessage: {
      show: false,
      color: "green",
      message: "",
    },
  },
  getters: {
    getRedirectedFromPlan(state) {
      return state.redirectedFromPlan;
    },
    getSnackBar(state) {
      return state.snackbarMessage;
    },
    getSocketMessage(state) {
      return state.socketMessage;
    },
    getIsNetworkError(state) {
      return state.isNetworkError;
    },
  },
  mutations: {
    setRedirectedFromPlans(state, payload) {
      state.redirectedFromPlan = payload;
    },
    setSocketMessage(state, payload) {
      state.socketMessage = {
        show: true,
        color: payload.color,
        message: payload.message,
      };
    },
    removeSocketMessage(state, payload) {
      state.socketMessage = {
        show: false,
        color: payload.color,
        message: payload.message,
      };
    },
    notifyUserMessage(state, message) {
      state.snackbarMessage = {
        show: true,
        color: "success",
        message: typeof message === "object" ? message.message : message,
        timeout: typeof message === "object" ? message.timeout || true : false,
      };
    },
    notifyInfoMessage(state, message) {
      state.snackbarMessage = {
        show: true,
        color: "info",
        message: typeof message === "object" ? message.message : message,
        timeout: typeof message === "object" ? message.timeout || true : false,
      };
    },
    notifyErrorMessage(state, message) {
      state.snackbarMessage = {
        show: true,
        color: "error",
        message: typeof message === "object" ? message.message : message,
        timeout: typeof message === "object" ? message.timeout || true : false,
      };
    },
    setIsNetworkError(state, payload) {
      state.isNetworkError = payload || false;
    },
    cancelSnackbar(state, show = false) {
      state.snackbarMessage = {
        show,
        color: "",
        message: "",
        timeout: false,
      };
    },
  },
};
