import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      userPermissions: "userPermissions",
      isInfoLoaded: "isInfoLoaded",
      getRoleID: "getRoleID",
    }),
    // Can manage app can do Create, Delete and Update of Apps.
    canManageApp() {
      return !!(this.userPermissions?.app && this.userPermissions?.app?.manage);
    },
    // This permission can only view invite list.
    canViewInvite() {
      return !!(
        this.userPermissions?.invite && this.userPermissions?.invite?.view
      );
    },
    canManageInvite() {
      return !!(
        this.userPermissions?.invite && this.userPermissions.invite?.manage
      );
    },
    // canViewRelease() {
    //   return !!(
    //     this.userPermissions.release && this.userPermissions.release.view
    //   );
    // },
    canManageRelease() {
      return !!(
        this.userPermissions?.release && this.userPermissions?.release?.manage
      );
    },
    canManageTeam() {
      return !!(
        this.userPermissions?.team && this.userPermissions?.team?.manage
      );
    },
    canManageFeedback() {
      return !!(
        this.userPermissions?.feedback && this.userPermissions?.feedback?.manage
      );
    },
    canManageStorage() {
      return !!(
        this.userPermissions?.storage && this.userPermissions?.storage?.manage
      );
    },
    canViewStorage() {
      return !!(
        this.userPermissions.storage && this.userPermissions.storage.view
      );
    },
    canManageTeamMembers() {
      return !!(
        this.userPermissions.member && this.userPermissions.member.manage
      );
    },
    canViewChat() {
      return !!(this.userPermissions.chat && this.userPermissions.chat.view);
    },
    canViewTeamMembers() {
      return !!(
        this.userPermissions?.member && this.userPermissions?.member?.view
      );
    },
    canViewTimeline() {
      return !!(
        this.userPermissions?.timeline && this.userPermissions?.timeline?.view
      );
    },
    isNotGuestRole() {
      return this.getRoleID !== "e5n28GYL1J";
    },
  },
};
