import ApiService from "@/core/services/api.service";
import {
  isEmpty
} from "@/core/services/helper.service";
import {
  coreApi,
  portalApi
} from "@/core/axios";
import apiRoutes from "@/consts/routes/apiRoutes";

// action types
export const CLEAR_APP = "clearApp";
export const INFO = "info";
export const UPDATE = "updateApp";
export const UPDATE_APP_SETTINGS = "updateAppSetting";
export const UPLOAD_APP_SCREENSHOT = "uploadAppScreenshot";
export const DELETE_APP_SCREENSHOT = "deleteAppScreenshot";
export const GET_APP_SCREENSHOT = "getAppScreenshot";
export const GET_APP_CATEGORIES = "getAppCategories";
export const GENERATE_APP_EXPORT = "generateAppExport";
export const APP_EXPORT_LIST = "appExportList";
export const UPDATE_IMAGE = "updateImage";
export const DELETE_IMAGE = "deleteImage";
export const LEAVE_APP = "leaveApp";
export const DELETE_APP = "deleteApp";
export const ADD_INVITE = "addInvite";
export const DELETE_INVITE = "deleteInvite";
export const UPDATE_INVITE = "updateInvite";
export const ENABLE_LOCK_PACKAGE = "enableLockPackage";
export const ENABLE_LOCK_UNIQUE_RELEASES = "enableLockUniqueReleases";
export const ENABLE_SESSIONS = "enableSessions";
export const CREATE_APP_TOKEN = "createAppToken";
export const UPDATE_APP_TOKEN = "updateAppToken";
export const LIST_APP_TOKENS = "listAppTokens";
export const DELETE_APP_TOKEN = "deleteAppToken";

// mutation types
export const SET_INFO = "info";
export const SET_MEMBERS = "members";
export const SET_INVITES = "invites";
export const UPDATE_APP = "updateApp";
export const SET_IMAGE = "updateImg";
export const REMOVE_MEMBER = "removeMember";
export const REMOVE_INVITE = "removeInvite";
export const CLEAR_APP_DATA = "clearAppData";
export const INVITED = "invited";
export const SET_APP_LIST = "setAppList";
export const REMOVE_APP_LIST = "removeAppList";
export const INVITE_UPDATED = "inviteUpdated";
export const MEMBER_UPDATED = "memeberUpdated";
export const GET_MEMBER_DEVICE = "GET_MEMBER_DEVICE";
export const SET_APP_MEMBER_LIST = "setAppMemberList";
export const GET_APP_CHART_STATS = "getAppChartStats";
export const GET_DASHBORD_APP_CHART_STATS = "getAppDashboardChartStats";

const state = {
  appActive: false,
  info: {},
  infoErrorMessage: null,
  defaultApp: null,
  invites: [],
  MemberDevices: [],
  releaseLoader: true,
  memberAppList: [],
  appCategoriesList: [],
  timelineLoading: true,
  appChartStats: {},
  membersLoading: true,
  appScreenShotList: [],
  infoLoaders: false,
};

const getters = {
  getInfoErrorMessage(state) {
    return state.infoErrorMessage;
  },
  getAppInfoLoading(state) {
    return state.infoLoaders;
  },
  getAppInfo(state) {
    return state.info;
  },
  getCategoriesList(state) {
    return state.appCategoriesList;
  },
  getAppChartStats(state) {
    return state.appChartStats;
  },
  getDefaultApp(state) {
    return state.defaultApp;
  },
  getMemberAppList(state) {
    return state.memberAppList;
  },
  getInvites(state) {
    return state.invites;
  },
  getAppScreenShotList(state) {
    return state.appScreenShotList;
  },
  getAppActivity(state) {
    return state.appActive;
  },
};

const actions = {
  [INFO](context, app_id) {
    context.commit("setInfoErrorMessage", null);
    return new Promise((resolve, reject) => {
      portalApi
        .get(apiRoutes.portal.app.details(app_id))
        .then(({
          data
        }) => {
          let response = data.data;
          context.commit(SET_INFO, {
            ...response,
            id: app_id
          });
          resolve({
            ...data,
            id: app_id
          });
        })
        .catch((error) => {
          context.commit(SET_INFO);
          reject(error);
        });
    });
  },
  [GET_APP_CHART_STATS](context, params) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${apiRoutes.portal.app.stats(params.id)}?year=${
            params ? params.year || "" : ""
          }`
        )
        .then(({
          data
        }) => {
          let response = data.data;
          context.commit("setAppChartStats", response);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GENERATE_APP_EXPORT](context, params) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${apiRoutes.portal.app.export(params.app_id)}/${params.type}`)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [APP_EXPORT_LIST](context, params) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${apiRoutes.portal.app.export(params.app_id)}/${
            params.type
          }?lastID=${params.lastId || ""}`
        )
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_APP_CATEGORIES](context) {
    return new Promise((resolve, reject) => {
      coreApi
        .get(apiRoutes.core.app.categories)
        .then(({
          data
        }) => {
          let response = data.data;
          context.commit("setAppCategories", response);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_DASHBORD_APP_CHART_STATS](context, params) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${apiRoutes.portal.dashboard.stats}?year=${
            params ? params.year || "" : ""
          }`
        )
        .then(({
          data
        }) => {
          let response = data.data;
          context.commit("setAppChartStats", response);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE](context, appInfo) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(apiRoutes.portal.app.details(appInfo.id), appInfo)
        .then(({
          data
        }) => {
          context.commit(UPDATE_APP, appInfo);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_APP_SETTINGS](context, appInfo) {
    if (appInfo.categories) {
      appInfo.categories = appInfo.categories.map((category) => {
        return category.name ? category.id : category;
      });
    }

    let {
      id,
      ...info
    } = appInfo;

    return new Promise((resolve, reject) => {
      portalApi
        .put(apiRoutes.portal.app.settings(appInfo.id), info)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_APP_SCREENSHOT](context, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(apiRoutes.portal.app.screenshots(payload.app_id))
        .then(({
          data
        }) => {
          context.commit("setAppScreenShotList", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPLOAD_APP_SCREENSHOT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/app/${payload.app_id}/screenshot`, payload.form)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_APP_SCREENSHOT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
          `/portal/app/${payload.app_id}/screenshot/${payload.screenshot_id}`
        )
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_IMAGE](context, params) {
    let {
      id,
      image
    } = params;
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/app/${id}/image`, image)
        .then(({
          data
        }) => {
          context.commit(SET_IMAGE, data?.image);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_IMAGE](context, app_id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/portal/app/${app_id}/image`)
        .then(({
          data
        }) => {
          context.commit(SET_IMAGE, "");
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_APP](context, app_id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/portal/app/${app_id}`)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LEAVE_APP](context, app_id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/app/${app_id}/leave`)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_INVITE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/app/${params.app_id}/member`, params.details)
        .then(({
          data
        }) => {
          context.commit(SET_APP_LIST, {
            id: params.app_id,
            name: params.name,
            platforms: params.platforms && params.platforms.length ? [...params.platforms] : [],
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // [UPDATE_MEMBER](context, { member_id, role_id, permissions }) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.put(`/core/team/member/${member_id}`, { role_id, permissions })
  //       .then(({ data }) => {
  //         // context.commit(MEMBER_UPDATED, { member_id, permissions });
  //         resolve(data);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  [DELETE_INVITE](context, {
    app_id,
    invite_id
  }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/core/app/${app_id}/invite/${invite_id}`)
        .then(({
          data
        }) => {
          context.commit(REMOVE_INVITE, invite_id);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_INVITE](context, {
    app_id,
    invite_id,
    permissions
  }) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/app/${app_id}/invite/${invite_id}`, {
          permissions
        })
        .then(({
          data
        }) => {
          context.commit(INVITE_UPDATED, {
            invite_id,
            permissions
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ENABLE_LOCK_PACKAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
          `/portal/app/${payload.app_id}/settings/lock-package`, {
            "enabled": payload.enabled
          }
        )
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ENABLE_LOCK_UNIQUE_RELEASES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
          `/portal/app/${payload.app_id}/settings/lock-unique-releases`, {
            "enabled": payload.enabled
          }
        )
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ENABLE_SESSIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
          `/core/app/${payload.app_id}/session/enable`, {
            "enabled": payload.enabled
          }
        )
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });

    })
  },
  [CREATE_APP_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/app/${payload.app_id}/token`, {
          name: payload.name
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });

    })
  },
  [UPDATE_APP_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/app/${payload.app_id}/token/${payload.id}`, {
          name: payload.name
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });

    })
  },
  [LIST_APP_TOKENS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/app/${payload.app_id}/tokens`)
        .then(({
          data
        }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [DELETE_APP_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/core/app/${payload.app_id}/token/${payload.id}`)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });

    })
  },
  [CLEAR_APP](context) {
    context.commit(CLEAR_APP_DATA);
  },
  [GET_MEMBER_DEVICE](context, path) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/${path}`)
        .then(({
          data
        }) => {
          context.commit("setTheMemberDevices", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setInfoErrorMessage(state, payload) {
    state.infoErrorMessage = payload;
  },
  toggleAppActivity(state, payload) {
    state.appActive = payload;
  },
  setAppScreenShotList(state, payload) {
    state.appScreenShotList = payload;
  },
  updateAppShareStatus(state, payload) {
    state.info.public = payload;
  },
  clearAppInfo(state, payload) {
    state.info = payload || {};
  },
  deductStorageFromAppInfo(state, payload) {
    state.info.storage.usage.releases =
      state.info.storage.usage.releases - payload || 0;
  },
  setAppCategories(state, payload) {
    state.appCategoriesList = payload;
  },
  setAppScreenshot(state, payload) {
    state.appScreenShotList.push(payload);
  },
  removeAppScreenshot(state, payload) {
    if (state.appScreenShotList && state.appScreenShotList.length) {
      state.appScreenShotList.splice(payload, 1);
    }
  },
  updateAppInfo(state, payload) {
    let info = state.info;

    //@Dipesh we need to organize this
    info.description = payload.description;
    info.categories = payload.categories;
    info.settings = payload.settings;

    state.info = info;
  },
  updateAppAutoArchiveSettings(state, payload) {
    state.info.settings.auto_archive_release = payload;
  },
  setAppChartStats(state, payload) {
    state.appChartStats = payload;
  },
  removeAppMemberStats(state) {
    if (state.info && state.info.stats) {
      let stats = state.info.stats;
      stats.total_members = stats.total_members ?
        stats.total_members - 1 :
        stats.total_members;
      state.info.stats = {
        ...stats
      };
    }
  },
  setDefaultApp(state, payload) {
    state.defaultApp = payload;
  },
  changePlatformAsArchived(state, payload) {
    state.info.platforms = payload;
  },
  appCommentCount(state) {
    state.info.stats.comments = state.info?.stats?.comments + 1;
  },
  removeMemberStatsCount(state) {
    if (state.info && state.info.stats) {
      let infoDetail = state.info;
      infoDetail.stats.total_members =
        state.info.stats && state.info.stats.total_members ?
        state.info.stats.total_members - 1 :
        0;
      state.info = {
        ...infoDetail
      };
    }
  },
  appendMemberStatsCount(state, count) {
    if (state.info && state.info.stats) {
      let infoDetail = state.info;
      infoDetail.stats.total_members =
        state.info.stats && state.info.stats.total_members ?
        state.info.stats.total_members + count :
        count;
      state.info = {
        ...infoDetail
      };
    }
  },
  setTheMemberDevices(state, payload) {
    state.MemberDevices = payload;
  },
  removeReleaseInfo(state, payload) {
    let infoDetail = state.info;
    if (payload.count) {
      if (payload.platform === "android") {
        infoDetail.stats.releases.android.active = state.info?.stats?.releases
          ?.android?.active ?
          state.info?.stats?.releases?.android?.active - payload.count :
          payload.count;
      } else {
        infoDetail.stats.releases.ios.active = state.info?.stats?.releases?.ios
          ?.active ?
          state.info?.stats?.releases?.ios?.active - payload.count :
          payload.count;
      }
    }
    if (payload.size) {
      infoDetail.storage.usage.releases =
        state.info.storage && state.info.storage.usage?.releases ?
        Math.ceil(state.info.storage.usage?.releases || 0) -
        Math.ceil(payload.size || 0) :
        0;
    }
    state.info = {
      ...infoDetail
    };
  },
  updateReleaseInfo(state, payload) {
    let infoDetail = state.info;
    if (state.info && state.info.stats) {
      if (payload.android) {
        infoDetail.stats.releases.android.active =
          state.info.stats && state.info.stats.releases.android.active ?
          state.info.stats.releases.android.active + payload.count :
          payload.count;
      }
      if (payload.ios) {
        infoDetail.stats.releases.ios.active =
          state.info.stats && state.info.stats.releases?.ios?.active ?
          state.info.stats.releases.ios.active + payload.count :
          payload.count;
      }
    }
    if (state.info && state.info.storage && payload.size) {
      infoDetail.storage.usage.releases = state.info.storage.usage.releases ?
        state.info.storage.usage.releases + payload.size :
        payload.size;
    }
    state.info = {
      ...infoDetail
    };
  },
  [SET_INFO](state, info) {
    if (!isEmpty(info)) {
      state.info = info;
    }
    state.infoLoaders = false;
  },
  [SET_MEMBERS](state, {
    members,
    invites
  }) {
    if (!isEmpty(members)) {
      state.members = members || [];
    }
    if (!isEmpty(invites)) {
      state.invites = invites || [];
    }
    state.membersLoading = false;
  },
  [UPDATE_APP](state, info) {
    state.info = {
      ...state.info,
      ...info
    };
  },
  [SET_IMAGE](state, image) {
    state.info = {
      ...state.info,
      image
    };
  },
  [REMOVE_MEMBER](state, member_id) {
    if (state.members) {
      state.members = state.members.filter((member) => member.id != member_id);
    }
  },
  [SET_APP_MEMBER_LIST](state, data) {
    state.memberAppList = [...data];
  },
  [REMOVE_INVITE](state, invite_id) {
    state.invites = state.invites.filter((invite) => invite.id != invite_id);
  },
  [INVITED](state, invite) {
    const invites = state.invites.slice();
    invites.unshift(invite);
    state.invites = invites;
  },
  [SET_APP_LIST](state, data) {
    let list = [...state.memberAppList];
    list.unshift(data);
    state.memberAppList = list;
  },
  [REMOVE_APP_LIST](state, data) {
    if (state.memberAppList) {
      state.memberAppList = state.memberAppList.filter((x) => x.id !== data.id);
    }
  },
  [INVITE_UPDATED](state, {
    invite_id,
    permissions
  }) {
    state.invites = state.invites.map((invite) => {
      if (invite.id == invite_id) {
        return {
          ...invite,
          permissions
        };
      } else {
        return invite;
      }
    });
  },
  [MEMBER_UPDATED](state, {
    member_id,
    permissions
  }) {
    state.members = state.members.map((member) => {
      if (member.id == member_id) {
        return {
          ...member,
          permissions
        };
      } else {
        return member;
      }
    });
  },
  [CLEAR_APP_DATA](state) {
    state.info = {};
    state.members = [];
    state.invites = [];
    state.appChartStats = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
