export default {
  home: "/",
  welcome: "/welcome",
  getStarted: "/get-started",
  help: "/help",
  app: {
    list: "/apps",
    details: {
      pattern: `/app/:app_id`,
      url(appId) {
        return this.pattern.replace(":app_id", appId);
      },
    },
    settings: {
      pattern: `/app/:app_id/settings`,
      url(appId) {
        return this.pattern.replace(":app_id", appId);
      },
    },
    feedbackDetails: {
      pattern: `/app/:app_id/sessions/:session_id/feedback/:feedback_id`,
      url(appId, sessionId, feedbackId) {
        return this.pattern
          .replace(":app_id", appId)
          .replace(":session_id", sessionId)
          .replace(":feedback_id", feedbackId);
      },
    },
    commentDetails: {
      pattern: `/app/:app_id/release/:release_id/comment/:comment_id`,
      url(appId, releaseId, commentId) {
        return this.pattern
          .replace(":app_id", appId)
          .replace(":release_id", releaseId)
          .replace(":comment_id", commentId);
      },
    },
  },
  session: {
    listFeedback: "/session/feedback",
    listActivities: "/session/activities",
  },
};
