import ApiService from "@/core/services/api.service";
import { isEmpty, get } from "@/core/services/helper.service";

// action types

export const GET_TEAM_TIMELINE = "getTeamTimeLines";

// mutation types
export const SET_TEAM_TIMELINE = "setTeamTimeLine";
export const CLEAR_TEAM_TIMELINE_DATA = "clearTeamTimelineData";

const state = {
  teamTimeline: [],
  totalTeamTimelineList: [],
  totalTeamTimelineCount: "",
  teamTimelineLoading: true,
};

const getters = {
  getTotalTeamTimeLineCount(state) {
    return state.totalTeamTimelineCount;
  },
  getTeamTimeline(state) {
    return state.teamTimeline;
  },
  getTotalTeamTimelineList(state) {
    return state.totalTeamTimelineList;
  },
};

const actions = {
  [GET_TEAM_TIMELINE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/team/timeline?lastID=${params.lastId}`)
        .then(({ data }) => {
          let response = data.data;
          let timeline = get(response[0], "data", []);
          if (params.lastId) {
            context.commit("setTeamTimelinePaginatedData", timeline);
          } else {
            context.commit(SET_TEAM_TIMELINE, timeline);
            context.commit(
              "setTotalTeamTimelineCount",
              get(response[0], "meta.total", 0)
            );
          }
          // this will add the timeline data and stop the loading
          resolve(data);
        })
        .catch((error) => {
          // this not gonna set any data just stop the loading, check this muttation out
          context.commit(SET_TEAM_TIMELINE);
          reject(error);
        });
    });
  },
};

const mutations = {
  setTotalTeamTimelineCount(state, payload) {
    state.totalTeamTimelineCount = payload;
  },
  setTeamTimelinePaginatedData(state, data) {
    let timeline = state.totalTeamTimelineList.length
      ? state.totalTeamTimelineList
      : state.teamTimeline;
    timeline = [...timeline, ...data];
    state.teamTimeline = [...data];
    state.totalTeamTimelineList = [...timeline];
  },
  setPreviousTeamTimelineData(state, data) {
    state.teamTimeline = [...data];
  },
  [SET_TEAM_TIMELINE](state, timeline) {
    if (!isEmpty(timeline)) {
      state.teamTimeline = timeline || [];
      state.totalTeamTimelineList = timeline || [];
    }
    state.teamTimelineLoading = false;
  },
  [CLEAR_TEAM_TIMELINE_DATA](state) {
    state.teamTimeline = [];
    state.totalTeamTimelineCount = 0;
    state.totalTeamTimelineList = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
