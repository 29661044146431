export const segmentEvents = {
  RELEASE_ERROR: "Release Error",
  RELEASE_RETRY: "Release retry",
  INSTALL_LOGS: "Opened installs logs",
  CLICKED_INSTALL_RELEASE: "Clicked install release button",
  CLICKED_ON_CONFIRM: "Clicked on confirm",
  INITIATE_DELETE_ACCOUNT: "Initiate delete account process",
  CONTACT_US: "Clicked on contact us",
  SCHEDULE_CALL: "Clicked on schedule call",
  CLICKED_ON_CANCEL: "Clicked on cancel",
  CANCEL_MODAL: "Cancel modal",
  DOWNLOAD_APP: "Download sample app",
  PRE_RELEASE: "Pre release",
  PRE_RELEASE_ERROR: "Pre release error",
  DELETE_ACCOUNT: "Clicked on delete account",
  OPEN_INSTALL_RELEASE: "Opened install release popup",
  WHAT_NEW: "Opened whats new",
  EMAIL_INVOICE_SUCCESS: "Email me my invoices success",
  EMAIL_INVOICE_FAILED: "Email me my invoices failed",
  OPEN_UPDATE_POPUP: "Opened update billing info popup",
  OPEN_RELEASE_INFO: "Opened install release info",
  INVALID_FILE: "Invalid file uploaded",
  INSTALL_PAGE_LOCKED: "Public install page status locked",
  OPEN_FEEDBACK_POPUP: "Opened feedback popup",
  TRIAL_POPUP: "Viewed trial popup",
  MAX_SIZE_UPLOAD: "Max size uploaded above 1GB",
  OPEN_SWITCH_TEAM: "Opened switch teams",
  OPEN_SWITCH_PLAN: "Opened switch plans",
  UPDATE_BILLING: "Opened update billing info popup",
  INSTALL_FEEDBACK_LOCKED: "Enable public install page feedback locked",
  CANCEL_PLAN: "initiated cancel plan",
  CLOSED_UPDATE: "Closed the update popup",
  OPEN_EDIT_PROFILE: "Open edit profile form",
  REDIRECT_LANDING: "Redirected from landing",
  SWITCH_RELEASE_VIEW: "Switched release view",
};
