import Pusher from "pusher-js";
import Vue from "vue";
import store from "@/store/index";
import JwtService from "@/core/services/jwt.service";

let pusherInstance = "";
export const connect = () => {
  window.Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG == "true";

  pusherInstance = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: "Token " + JwtService.getToken(),
        "Express-Token": store.getters.expressReleaseToken,
      },
    },
  });

  pusherInstance.connection.bind("initialized", function() {});

  pusherInstance.connection.bind("connecting", function() {
    store.commit("setSocketMessage", {
      message: "Connecting...",
      color: "success",
    });
  });

  pusherInstance.connection.bind("connected", function() {
    store.commit("removeSocketMessage", {});
  });

  pusherInstance.connection.bind("unavailable", function() {
    store.commit("setSocketMessage", {
      message:
        "Connection failed, please check your internet connection and reload your page",
      color: "error",
    });
  });

  pusherInstance.connection.bind("failed", function() {
    store.commit("setSocketMessage", {
      message: "Connection failed. Retrying...",
      color: "error",
    });
  });

  pusherInstance.connection.bind("disconnected", function() {
    store.commit("setSocketMessage", {
      message: "Connection disconnected. Retrying...",
      color: "warning",
    });
  });

  Vue.prototype.$pusher = pusherInstance;
};
