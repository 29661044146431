import ApiService from "@/core/services/api.service";
import { isEmpty } from "@/core/services/helper.service";
// action types
export const GET_INVITE = "getInvite";
export const CREAT_INVITE = "creatInvite";
export const GET_ALL_INVITES = "getAllInvites";
export const UPDATE_INVITES = "updateInvites";
export const DELETE_INVITES = "deleteInvites";
export const ACCEPT_DECLINE = "accept_decline";
export const GET_INVITE_LIST = "getInviteList";
export const INVITE_BY_URL = "INVITE_BY_URL";
export const GET_STARTED = "getStarted";
export const CLEAR_INVITE_DATA = "clearInviteData";

const state = {
  inviteErrMsg: null,
  inviteSuccMsg: null,
  inviteLoader: null,
  inviteURL: null,
  listOfInvites: null,
  totalInvitesCount: "",
  totalListOfInvites: [],
  inviteInfo: null,
  emailInvites: null,
  urlInvites: null,
  totalTeamInviteCount: 0,
  totalTeamInvitesList: [],
  teamInvitesList: [],
  notificationInviteList: [],
  inviteProcces: null,
  inviteUrlList: null,
  dashboardInviteLoader: true,
  invitesLoader: true,
};

const getters = {
  getTotalInviteList(state) {
    return state.totalListOfInvites;
  },
  getNotificationInviteList(state) {
    return state.notificationInviteList;
  },
  getTotalInvitesListCount(state) {
    return state.totalInvitesCount;
  },
  getEmailList(state) {
    return state.emailInvites;
  },
  getTeamInvitesList(state) {
    return state.teamInvitesList;
  },
  getTotalTeamInvitesList(state) {
    return state.totalTeamInvitesList;
  },
  getTotalTeamInvitesCount(state) {
    return state.totalTeamInviteCount;
  },
  getUrlTotalList(state) {
    return state.totalUrlInvites;
  },
  getUrlList(state) {
    return state.urlInvites;
  },
  getInviteUrl(state) {
    return state.inviteUrlList;
  },
};

const actions = {
  [GET_INVITE](context, invite_id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/invite/${invite_id}`)
        .then(({ data }) => {
          context.commit("setInviteInfo", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_STARTED](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/invite/${params.id}/get-started`, {
        email: params.email,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CREAT_INVITE](context, invite_data) {
    // reset the messages
    context.commit("setSuccMessage", "");
    context.commit("setErrMessage", "");

    // set the loader
    context.commit("setInviteLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/invite`, invite_data)
        .then(({ data }) => {
          context.commit("setInviteURL", data.url);
          context.commit("setInviteLoader", false);
          resolve(data);
        })
        .catch((error) => {
          context.commit("setInviteLoader", false);
          reject(error);
        });
    });
  },
  [UPDATE_INVITES](context, invite_data) {
    // reset the messages
    context.commit("setErrMessage", "");
    context.commit("setInviteLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/team/invite/${invite_data.id}`, invite_data.data)
        .then(({ data }) => {
          resolve(data);
          context.commit("notifyUserMessage", data.message || data);
          context.commit("setInviteLoader", false);
        })
        .catch((error) => {
          context.commit("notifyUserMessage", error.message || error);
          context.commit("setInviteLoader", false);
          reject(error);
        });
    });
  },
  [DELETE_INVITES](context, id) {
    // reset the messages
    context.commit("setSuccMessage", "");
    context.commit("setErrMessage", "");
    context.commit("setInviteLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.delete(`/core/team/invite/${id}`)
        .then(({ data }) => {
          context.commit("setSuccMessage", data.message || data);
          context.commit("setInviteLoader", false);
          resolve(data);
        })
        .catch((error) => {
          context.commit("setErrMessage", error.message || error);
          context.commit("setInviteLoader", false);

          reject(error);
        });
    });
  },
  // for the dashbord empty
  [GET_ALL_INVITES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/invites?lastID=${params ? params.lastId || "" : ""}`)
        .then(({ data }) => {
          if (params && params.lastID) {
            context.commit("setTeamInvitesPaginatedData", data.data);
          } else {
            context.commit("setTeamInvites", data.data);
            context.commit(
              "setTotalTeamInvitesCount",
              data && data.meta ? data.meta.total : 1
            );
          }
          resolve(data);
        })
        .catch(function (error) {
          // it setting no data just stopping the loader
          context.commit("setListOfInvites");
          reject(error);
        });
    });
  },
  //
  [ACCEPT_DECLINE](context, { id, actionType }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/invite/${id}/${actionType}`)
        .then(({ data }) => {
          context.commit("setSuccMessage", data.message || data);
          resolve(data);
        })
        .catch((error) => {
          context.commit("setErrMessage", error.message || error);
          reject(error);
        });
    });
  },
  [GET_INVITE_LIST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/team/invites?lastID=${params ? params.lastID || "" : ""}&appID=${
          params ? params.appID || "" : ""
        }`
      )
        .then(({ data }) => {
          if (params && params.lastID) {
            context.commit("setTeamInvitesPaginatedData", data.data);
          } else {
            context.commit("setTeamInvites", data.data);
            context.commit("setTotalTeamInvitesCount", data?.meta?.count || 0);
            context.commit(
              "setTotalMembersCount",
              data && data.meta ? data.meta.total_members : 0
            );
          }
          context.commit("setInviteListURL", data.meta?.url);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  clearInvitesList(state, payload) {
    state.inviteList = payload;
  },
  setTotalTeamInvitesCount(state, payload) {
    state.totalTeamInviteCount = payload;
  },
  setTeamInvitesPaginatedData(state, data) {
    let invite = state.totalTeamInvitesList.length
      ? state.totalTeamInvitesList
      : state.teamInvitesList;
    invite = [...invite, ...data];
    state.teamInvitesList = [...data];
    state.totalTeamInvitesList = [...invite];
  },
  setPreviousTeamInviteData(state, data) {
    state.teamInvitesList = [...data];
  },
  whenUserInInviteProcess(state, payload) {
    state.inviteProcces = payload;
  },
  removeFromTeamInvites(state, id) {
    state.teamInvitesList = state.teamInvitesList.filter(
      (ele) => ele.id !== id
    );
    state.totalTeamInvitesList = state.totalTeamInvitesList.filter(
      (ele) => ele.id !== id
    );
  },
  appendTeamInvites(state, data) {
    if (state.teamInvitesList && state.teamInvitesList.length) {
      if (data.id && !data.create) {
        const index = state.teamInvitesList.findIndex(
          (el) => el.id === data.id
        );
        state.teamInvitesList.splice(index, 1, data);
      } else {
        state.teamInvitesList = [data, ...state.teamInvitesList];
      }
    } else if (data.append) {
      state.teamInvitesList = [];
      state.teamInvitesList.unshift(data);
    }
    return;
  },
  setUrlInvites(state, data) {
    if (data && !isEmpty(data)) {
      state.totalUrlInvites = data;
      state.urlInvites = data;
    }
    state.urlsLoader = false;
  },
  setInviteInfo(state, info) {
    state.inviteInfo = info;
  },
  // this will set a message when data complete send, error or secces
  setListOfInvites(state, list) {
    !isEmpty(list) ? (state.listOfInvites = list) : (state.listOfInvites = []);
    !isEmpty(list)
      ? (state.totalListOfInvites = list)
      : (state.listOfInvites = []);
    state.dashboardInviteLoader = false;
  },
  setNotificationInviteList(state, data) {
    if (state.notificationInviteList && state.notificationInviteList.length) {
      state.notificationInviteList = [...state.notificationInviteList, ...data];
    } else {
      state.notificationInviteList = [...data];
    }
  },
  clearNotificationInviteList(state) {
    state.notificationInviteList = [];
  },
  setTotalInvitesListPaginatedData(state, data) {
    let invite = state.totalListOfInvites.length
      ? state.totalListOfInvites
      : state.listOfInvites;
    invite = [...invite, ...data];
    state.listOfInvites = [...data];
    state.totalListOfInvites = [...invite];
  },
  setTotalInvitesPreviousData(state, payload) {
    state.listOfInvites = [...payload];
  },
  setTotalInvitesDataCount(state, payload) {
    state.totalInvitesCount = payload;
  },
  setTeamInvites(state, payload) {
    state.teamInvitesList = payload;
    state.totalTeamInvitesList = payload;
  },
  setInviteURL(state, url) {
    state.inviteURL = url;
  },
  setInviteListURL(state, url) {
    state.inviteUrlList = url;
  },
  setErrMessage(state, value) {
    state.inviteErrMsg = value;
  },
  setSuccMessage(state, value) {
    state.inviteSuccMsg = value;
  },
  setInviteLoader(state, value) {
    state.inviteLoader = value;
  },
  [CLEAR_INVITE_DATA](state) {
    state.inviteURL = null;
    state.listOfInvites = null;
    state.inviteInfo = null;
    state.emailInvites = null;
    state.urlInvites = null;
    state.totalEmailInviteCount = 0;
    state.totalUrlInviteCount = 0;
    state.totalEmailInvites = [];
    state.totalUrlInvites = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
