import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import VueQrcode from "@chenfengyuan/vue-qrcode";

Vue.component("VueInlineAlert", () =>
  import("@/view/components/Common/VueInlineAlert.vue")
);
Vue.component("ContentLoader", () =>
  import("@/view/components/Common/ContentLoader.vue")
);
Vue.component("VueHoverableDate", () =>
  import("@/view/components/Common/HoverableDate")
);
Vue.component("AutoComplete", () =>
  import("@/view/components/Common/AutoComplete.vue")
); // the info tooltip
Vue.component("ActionDropdown", () =>
  import("@/view/components/Common/ActionDropdown")
);
Vue.component("VueSnackBar", () =>
  import("@/view/components/Common/VueSnackbar.vue")
);
Vue.component("PlatformIcon", () =>
  import("@/view/components/App/PlatformIcon.vue")
); // sidePanel (navigation drawer)
Vue.component("VueTooltip", () =>
  import("@/view/components/Common/vueTooltip.vue")
); // sidePanel (navigation drawer)
Vue.component("SidePanel", () =>
  import("@/view/components/Common/SidePanel.vue")
); // lodaer when website loading
Vue.component("InlineSvg", () =>
  import("@/view/components/Common/InlineSvg.vue")
);
Vue.component("ProfileImage", () => import("@/view/components/User/Image.vue"));
Vue.component("Loader", () => import("@/view/components/Common/VueLoader.vue")); // no data found
Vue.component("MaxText", () => import("@/view/components/Common/MaxText.vue"));
Vue.component("noData", () => import("@/view/components/Common/NoData.vue")); // loader when the data inside the website loading, any data
Vue.component("Modal", () => import("@/view/components/Common/Modal.vue"));

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.component(VueQrcode.name, VueQrcode);

Vue.component("Snackbar", () =>
  import("@/view/components/Common/Snackbar.vue")
);
Vue.component("FlexRow", () => import("@/view/components/Common/FlexRow.vue"));
Vue.component("Stack", () => import("@/view/components/Common/Stack.vue"));
Vue.component("Logo", () => import("@/view/components/TestAppLogo.vue"));
Vue.component("AndroidLogo", () => import("@/view/components/AndroidLogo.vue"));
Vue.component("AppleLogo", () => import("@/view/components/AppleLogo.vue"));
Vue.component("GalleryFolderLogo", () =>
  import("@/view/components/GalleryFolderLogo.vue")
);

Vue.component("DateTooltip", () =>
  import("@/view/components/Common/DateTooltip.vue")
);

Vue.component("Characteristics", () =>
  import("@/view/components/Common/Characteristics/index.vue")
);

Vue.component("Empty", () => import("@/view/components/Common/Empty.vue"));

Vue.component("SvgIcon", () => import("@/view/components/Common/SvgIcon.vue"));

Vue.component("StatsPanel", () =>
  import("@/view/components/Common/StatsPanel/index.vue")
);

Vue.component("Device", () => import("@/view/components/Common/Device.vue"));

Vue.component("ReleaseChip", () =>
  import("@/view/components/Common/ReleaseChip.vue")
);
