// import { get } from "lodash";
import ApiService from "@/core/services/api.service";

// action types

// mutation types
export const CLEAR_TEAM = "clearTeamInfo";
export const SET_INFO = "setInfo";
export const UPDATE_TEAM_INFO = "updateTeamInfo";
export const UPDATE_TEAM = "updateTeam";
export const GET_TEAMS = "getTeams";
export const SWITCH_TEAM = "switchTeams";
export const LEAVE_TEAM = "deleteTeam";
export const GET_TEAM_INFO = "getTeamInfo";
export const CHECK_TEAM = "checkTeam";
export const CREATE_TEAM_INFO = "createTeamInfo";

const state = {
  // init the info
  info: {},
  // this is when data are loading by default will be true but when data done fetching or ther is no data will be false
  memberInvitesLoader: true,
  teamList: [],
  showSwitchModal: false,
  totalTeamList: [],
  totalTeamCount: 0,
};

const getters = {
  getTeamList(state) {
    return state.teamList;
  },
  getSwitchModal(state) {
    return state.showSwitchModal;
  },
  getTeamInfo(state) {
    return state.info;
  },
  getTotalTeamList(state) {
    return state.totalTeamList;
  },
  getTotalTeamCount(state) {
    return state.totalTeamCount;
  },
};

const actions = {
  [GET_TEAMS](context, lastID) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/teams?lastID=${lastID || ""}&limit=4`)
        .then(({ data }) => {
          if (lastID) {
            context.commit(`setTeamListPaginatedData`, data.data[0].data);
          } else {
            context.commit("setTeamList", data.data[0].data);
            context.commit("setTotalTeamCount", data.data[0].meta.count);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SWITCH_TEAM](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.post("core/team/switch", { id })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_TEAM](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("core/team", params)
        .then((response) => {
          context.commit(UPDATE_TEAM_INFO, params);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_TEAM](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("core/team/check", params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LEAVE_TEAM](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete("core/team", params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_TEAM_INFO](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("core/team")
        .then(({ data }) => {
          context.commit(SET_INFO, data.data);
          if (data.data && data.data.plan) {
            context.commit("updateUserUsages", data.data.plan.usage);
          }
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_INFO, {});
          reject(error);
        });
      //
    });
  },
  [CREATE_TEAM_INFO](context, dataToPost) {
    return new Promise((resolve, reject) => {
      ApiService.post("core/team", dataToPost)
        .then(({ data }) => {
          if (!dataToPost.fromSwitch) {
            context.commit(SET_INFO, data.data);
            context.commit("updateTeamId", data.id);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      //
    });
  },
};

const mutations = {
  updateTeamName(state, payload) {
    if (state.info && state.info.name) {
      state.info.name = payload.name;
      state.info.info = payload.info;
    }
    if (state.teamList.length && state.totalTeamList.length) {
      state.totalTeamList = state.totalTeamList.map((item) => {
        if (item.id === payload.id) {
          item.name = payload.name;
          item.info = {
            info: payload.info,
          };
        }
        return item;
      });

      state.teamList = state.teamList.map((item) => {
        if (item.id === payload.id) {
          item.name = payload.name;
          item.info = {
            info: payload.info,
          };
        }
        return item;
      });
    }
  },
  setSwitchModal(state, payload) {
    state.showSwitchModal = payload || false;
  },
  setTeamList(state, payload) {
    state.teamList = payload;
    state.totalTeamList = payload;
  },
  setTotalTeamCount(state, payload) {
    state.totalTeamCount = payload;
  },
  setTeamListPaginatedData(state, data) {
    let team = state.totalTeamList.length
      ? state.totalTeamList
      : state.teamList;
    team = [...team, ...data];
    state.teamList = [...data];
    state.totalTeamList = [...team];
  },
  setPreviousTeamData(state, payload) {
    state.teamList = [...payload];
  },
  removeAppStats(state, payload) {
    if (state.info) {
      let infoDetail = state.info;
      if (payload.externalStorage && infoDetail.usage) {
        infoDetail.usage.external_storage =
          state.info && state.info.usage
            ? Math.ceil(state.info.usage.external_storage) -
              Math.ceil(payload.externalStorage)
            : 0;
      }
      if (payload.storage && infoDetail.usage) {
        infoDetail.usage.storage =
          state.info && state.info.usage && state.info.usage.storage
            ? Math.ceil(state.info.usage.storage) - Math.ceil(payload.storage)
            : 0;
      }
      if (payload.appCount && infoDetail.meta) {
        infoDetail.meta.total_apps =
          state.info && state.info.meta
            ? state.info.meta.total_apps - payload.appCount
            : 0;
      }
      if (payload.releaseCount && infoDetail.meta) {
        infoDetail.meta.total_releases =
          state.info && state.info.meta
            ? state.info.meta.total_releases - payload.releaseCount
            : payload.releaseCount;
      }
    }
  },
  appendAppStats(state, payload) {
    if (state.info) {
      let infoDetail = state.info;
      if (payload.externalStorage && infoDetail.usage) {
        infoDetail.usage.external_storage =
          state.info && state.info.usage
            ? state.info.usage.external_storage + payload.externalStorage
            : state.info.usage.external_storage;
      }
      if (payload.appCount && state.info.meta) {
        infoDetail.meta.total_apps =
          state.info && state.info.meta
            ? state.info.meta.total_apps + payload.appCount
            : payload.appCount;
      }
      if (payload.storage && infoDetail.usage) {
        infoDetail.usage.storage =
          state.info && state.info.usage
            ? state.info.usage.storage + payload.storage
            : payload.storage;
      }
      if (payload.releaseCount && infoDetail.meta) {
        infoDetail.meta.total_releases =
          state.info && state.info.meta
            ? state.info.meta.total_releases + payload.releaseCount
            : payload.releaseCount;
      }
    }
  },
  removeStorageStats(state, payload) {
    let infoDetail = state.info;
    infoDetail.meta = {
      total_apps:
        state.info.meta && state.info.meta.total_apps
          ? state.info.meta.total_apps - payload.app
          : 0,
      total_releases:
        state.info.meta && state.info.meta.total_releases
          ? state.info.meta.total_releases - payload.releases
          : 0,
      total_members:
        state.info.meta && state.info.meta.total_members
          ? state.info.meta.total_members
          : 0,
    };
    if (infoDetail && infoDetail.usage) {
      infoDetail.usage.external_storage =
        state.info.usage && state.info.usage.external_storage
          ? state.info.usage.external_storage - payload.storage
          : 0;
    }
    state.info = { ...infoDetail };
  },
  [CLEAR_TEAM](state) {
    state.info = {};
    state.allMembers = {};
    state.teamList = [];
  },
  [UPDATE_TEAM_INFO](state, payload) {
    let info = { ...state.info };
    info.name = payload.name;
    info.description = payload.description;
    state.info = { ...info };
  },
  [SET_INFO](state, payload) {
    // place each data to there places
    state.info = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
