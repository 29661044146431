import dayjs from "dayjs";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const getReleaseTime = (timestamp) => {
  timestamp = timestamp * 1000;
  let dt = new Date(timestamp);
  return `${dayjs(dt).format("DD MMM, YYYY")} at ${dayjs(dt).format(
    "hh:mm A"
  )}`;
};
export const currentTimestamp = () => {
  return Date.now() / 1000;
};
export const getDurationOnDays = (time) => {
  return Math.floor(time / 86400) + " days";
};
export const numberWithCommas = (num) => {
  if (typeof num == "number") {
    return num.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
};
export const getTimelineTime = (timestamp) => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return dayjs(dt).fromNow();
  } else {
    return `${dayjs(dt).format("DD MMM, YYYY")} at ${dayjs(dt).format(
      "hh:mm A"
    )}`;
  }
};
export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};
export const get = (value, path, defaultValue) => {
  return String(path)
    .split(".")
    .reduce((acc, v) => {
      try {
        acc = acc[v];
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value);
};
export const getRelativeOrAbsoluteTime = (timestamp) => {
  if (!timestamp) return "No date";

  const FIVE_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 5;
  timestamp = timestamp * 1000;
  const eventDate = dayjs(new Date(timestamp));
  const now = dayjs();

  if (now.diff(eventDate, "millisecond") < FIVE_DAYS_IN_MS) {
    return eventDate.fromNow();
  } else {
    if (now.year() === eventDate.year()) {
      return `${eventDate.format("DD MMM")} at ${eventDate.format("hh:mm A")}`;
    } else {
      return `${eventDate.format("DD MMM, YYYY")} at ${eventDate.format(
        "hh:mm A"
      )}`;
    }
  }
};

export const getUnixTime = (timestamp) => dayjs(timestamp).unix();

export const getInstallTime = (timestamp) => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return dayjs(dt).fromNow();
  } else {
    return dayjs(dt).format("DD MMM, YYYY");
  }
};

export const UID = () => {
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? "" : "-") + array[i].toString(16).slice(-4);
  }
  return str;
};

export const formatBytes = (a, b = 2) => {
  if (!a || 0 === a || a === undefined) return "0 Bytes";
  a = a * 1000;
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1000));
  return (
    parseFloat((a / Math.pow(1000, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

export const getAPIFullURL = (url, params) => {
  if (params) {
    const count = Object.keys(params).length;

    if (count == 0) {
      return url;
    } else if (count == 1) {
      const key = Object.keys(params)[0];
      return `${url}?${key}=${[params[key]]}`;
    } else {
      let fullURL = `${url}?`;
      for (const property in params) {
        fullURL += `${property}=${params[property]}&`;
      }
      return fullURL;
    }
  } else {
    return url;
  }
};

export const getDuration = (startAt, endAt) => {
  const startTime = dayjs(startAt);
  const endTime = endAt ? dayjs(endAt) : dayjs(); // Use current time if endTime is not provided
  const seconds = endTime.diff(startTime, "second");

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const displayHours = hours.toString().padStart(2, "0");
  const displayMinutes = minutes.toString().padStart(2, "0");
  const displaySeconds = (seconds % 60).toString().padStart(2, "0");

  return `${displayHours}:${displayMinutes}:${displaySeconds}`;
};

export function shortenVersion(str, maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength || 3) + "..." + str.slice(-3);
  }
  return str;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift().trim();
  }
  return null; // Explicitly return null if cookie isn't found
}
