import ApiService from "@/core/services/api.service.js";
export const GENERATE_SLACK_AUTH_LINK = "generateSlackAuthLink";
export const GET_INTEGRATION_LIST = "getIntegrationList";
export const GET_APP_INTEGRATION_LIST = "getAppIntegrationList";
export const ENABLE_MICROSOFT_TEAM = "enableMicrosoftTeam";
export const ENABLE_APP_MICROSOFT_TEAM = "enableAppMicrosoftTeam";
export const DISABLE_APP_MICROSOFT_TEAM = "disableAppMicrosoftTeam";
export const DISABLE_MICROSOFT_TEAM = "disableMicrosoftTeam";
export const REMOVE_MICROSOFT_TEAM_INTEGRATION =
  "removeMicrosoftTeamIntegration";
export const ENABLE_SLACK = "enableSlack";
export const ENABLE_APP_SLACK = "enableAppSlack";
export const TEAM_INTEGRATION_CHECK = "teamIntegrationCheck";
export const DISABLE_SLACK = "disableSlack";
export const REMOVE_SLACK_INTEGRATION = "removeSlackIntegration";
export const INSTALL_SLACK_INTEGRATION = "installSlackIntegration";
export const DISABLE_APP_SLACK = "disableAppSlack";

const state = {
  integrationList: [],
  appIntegrations: [],
  selectedIntegration: null,
};
const getters = {
  getIntegrationList(state) {
    return state.integrationList;
  },
  getAppIntegrations(state) {
    return state.appIntegrations;
  },
  getSelectedIntegration(state) {
    return state.selectedIntegration;
  },
};
const actions = {
  [GENERATE_SLACK_AUTH_LINK]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/team/integrations/slack/generate-auth-link`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [TEAM_INTEGRATION_CHECK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/team/integrations/check`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_MICROSOFT_TEAM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/portal/team/integrations/microsoft-teams/enable`,
        payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_APP_MICROSOFT_TEAM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/portal/app/${payload.app_id}/integrations/microsoft-teams/enable`,
        payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DISABLE_MICROSOFT_TEAM]() {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/team/integrations/microsoft-teams/disable`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DISABLE_APP_MICROSOFT_TEAM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `/portal/app/${payload.app_id}/integrations/microsoft-teams`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_INTEGRATION_LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/team/integrations`)
        .then(({ data }) => {
          resolve(data);
          context.commit("setIntegrationList", data || []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_APP_INTEGRATION_LIST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/app/${params.app_id}/integrations`)
        .then(({ data }) => {
          resolve(data);
          context.commit("setAppIntegrationList", data.data || []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_SLACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/team/integrations/slack/enable`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_APP_SLACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/portal/app/${payload.app_id}/integrations/slack/enable`,
        payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DISABLE_APP_SLACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/portal/app/${payload.app_id}/integrations/slack`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DISABLE_SLACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/team/integrations/slack/disable`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INSTALL_SLACK_INTEGRATION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/team/integrations/slack/install`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REMOVE_SLACK_INTEGRATION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/portal/team/integrations/slack`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REMOVE_MICROSOFT_TEAM_INTEGRATION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/portal/team/integrations/microsoft-teams`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setIntegrationList(state, payload) {
    state.integrationList = payload || [];
  },
  setSelectedIntegration(state, payload) {
    state.selectedIntegration = payload;
  },
  setAppIntegrationList(state, payload) {
    state.appIntegrations = payload || [];
  },
};

export default { actions, getters, mutations, state };
