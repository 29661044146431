import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import * as Sentry from "@sentry/vue";

Vue.use(Router);

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const isAuthenticated = (to, from, next) => {
  const token = store && store.getters.isAuthenticated;
  const isInsideAuth = to.matched.some(
    (record) => record?.parent?.path === "/auth"
  );
  if (!token && !isInsideAuth) {
    store.commit("setRedirectionUrl", to);
    next({
      name: "signin",
    });
    return;
  } else if (token && isInsideAuth) {
    next({
      name: "dashboard",
      query: to.query,
    });
    return;
  }
  next();
};

const fetchUserRoles = async (to, from, next) => {
  const userRoles = store?.getters?.userRoles;

  if (!userRoles.team) {
    try {
      await store.dispatch("getUserRoles");
      next();
    } catch (error) {
      store.commit("notifyErrorMessage", error.message);
      Sentry.captureException(error);
      return;
    }
  } else {
    next();
    return;
  }
};

const checkKYC = (to, from, next) => {
  const isKycComplete = store.getters.getKycFormStatus;

  // If the target route is 'kyc' and KYC is not complete, let the user proceed
  if (to.name === "kyc" && !isKycComplete) {
    next();
    return;
  }

  // If KYC is complete, let the user proceed to their intended route
  if (isKycComplete) {
    next();
    return;
  }

  // If KYC is not complete and the user is not trying to access 'kyc', redirect them to 'kyc'
  if (!isKycComplete) {
    next({
      name: "kyc",
    });
    return;
  }

  next();
};

const redirectToStoredURL = (to, from, next) => {
  const getRedirectionUrl = store?.getters?.getRedirectionUrl;
  if (getRedirectionUrl?.name) {
    next(getRedirectionUrl);
    store.commit("setRedirectionUrl", null);
  } else {
    next();
  }
};

const verifyPartOfTeam = (to, from, next) => {
  const isPartofTeam = !!store.getters.getTeamId;

  if (to.name === "welcome" && isPartofTeam) {
    next({
      name: "dashboard",
    });
    return;
  }

  if (to.name === "welcome" && !isPartofTeam) {
    next();
    return;
  }

  if (to.name === "inactive") {
    next();
    return;
  }

  if (isPartofTeam || to.name === "kyc") {
    next();
  } else {
    next({
      name: "welcome",
    });
  }
};

const checkMemberStatus = (to, from, next) => {
  const isInactiveMember = store.getters.getIsUserDisabled;

  // If coming from the 'kyc' route, don't redirect again.
  if (to.name === "kyc") {
    next();
    return;
  }

  if (isInactiveMember) {
    if (to.name !== "inactive") {
      next({
        name: "inactive",
      });
      return;
    } else {
      next();
      return;
    }
  } else {
    if (to.name === "inactive") {
      next({
        name: "dashboard",
      });
      return;
    } else {
      next();
      return;
    }
  }
};

const chainGuards = (...guards) => {
  let guardExecutionCount = 0; // to keep track of the number of times guards are executed
  const MAX_EXECUTION_COUNT = 10; // change this to whatever threshold you think is appropriate

  return (to, from, next) => {
    if (guardExecutionCount >= MAX_EXECUTION_COUNT) {
      return next(false); // stop navigation
    }

    guardExecutionCount++;

    const executeNextGuard = (index) => {
      if (guards[index]) {
        guards[index](to, from, (nextDecision) => {
          if (nextDecision === undefined) {
            executeNextGuard(index + 1);
          } else {
            next(nextDecision);
          }
        });
      } else {
        next();
      }
    };

    // Check if we are being redirected back to the same route
    if (from && to.name === from.name) {
      return next(false); // Abort navigation to avoid infinite loop
    }

    executeNextGuard(0);
  };
};

const welcomeRoutes = [
  {
    path: "/dashboard",
    name: "Empty Dashboard",
    component: () => import("@/view/layout/DashboardEmpty.vue"),
    children: [
      {
        path: "/get-to-know-you",
        name: "kyc",
        component: () =>
          import("@/view/pages/user/welcome/get-to-know-you.vue"),
      },
      {
        path: "/welcome",
        name: "welcome",
        component: () => import("@/view/pages/user/welcome/index.vue"),
      },
      {
        path: "/inactive",
        name: "inactive",
        component: () => import("@/view/pages/user/welcome/inactive-user.vue"),
      },
      {
        path: "/get-started",
        name: "get-started",
        component: () => import("@/view/pages/user/get-started/index.vue"),
      },
      {
        path: "/get-started/team",
        name: "started-team",
        component: () => import("@/view/pages/user/get-started/index.vue"),
      },
      {
        path: "/get-started/plan",
        name: "started-app",
        component: () => import("@/view/pages/user/get-started/index.vue"),
      },
      {
        path: "/get-started/invite",
        name: "started-invite",
        component: () => import("@/view/pages/user/get-started/index.vue"),
      },
      {
        path: "/get-started/download-app",
        name: "download-app",
        component: () => import("@/view/pages/user/get-started/index.vue"),
      },
      {
        path: "/get-started/release",
        name: "started-releases",
        component: () => import("@/view/pages/user/get-started/index.vue"),
      },
    ],
  },
];

const userRoutes = [
  {
    path: "/notifications",
    name: "user-notification",
    component: () => import("@/view/pages/user/notifications.vue"),
  },
  {
    path: "/settings",
    name: "user-settings",
    component: () => import("@/view/pages/settings.vue"),
  },
  {
    path: "/settings/api-credentials",
    name: "api-tokens",
    component: () => import("@/view/pages/settings.vue"),
  },
  {
    path: "/settings/profile",
    name: "profile-settings",
    component: () => import("@/view/pages/settings.vue"),
  },
  {
    path: "/settings/notification",
    name: "user-notification-settings",
    component: () => import("@/view/pages/settings.vue"),
  },
];

const appRoutes = [
  {
    path: "/app/:app_id",
    name: "app-detail",
    component: () => import("@/view/components/AppLayout.vue"),
    redirect: "/app/:app_id/dashboard",
    children: [
      {
        path: "integrations",
        redirect: (to) => {
          // Preserve query and hash during redirect
          return {
            path: "/integration",
            query: to.query,
            hash: to.hash,
          };
        },
      },
      {
        path: "integration/:tab?",
        name: "app-integrations",
        component: () => import("@/view/pages/app/integrations.vue"),
        props: true,
      },
      {
        path: "chat",
        name: "app-chat",
        component: () => import("@/view/components/App/Chat/MessageBox.vue"),
      },
      {
        path: "sessions/users",
        name: "app-sessions-users",
        component: () => import("@/view/pages/sessions/users.vue"),
      },
      {
        path: "sessions/devices",
        name: "app-sessions-devices",
        component: () => import("@/view/pages/sessions/devices.vue"),
      },
      {
        path: "sessions/applications",
        name: "app-sessions-applications",
        component: () => import("@/view/pages/sessions/applications.vue"),
      },
      {
        path: "sessions/activities",
        name: "app-sessions-activities",
        component: () => import("@/view/pages/sessions/activities.vue"),
      },
      {
        path: "sessions/feedback",
        name: "app-sessions-feedback",
        component: () => import("@/view/pages/sessions/feedback.vue"),
      },
      {
        path: "sessions/:session_id",
        name: "sessions-detail",
        component: () => import("@/view/pages/sessions/session_detail.vue"),
        redirect: "/app/:app_id/sessions/:session_id/overview",
        children: [
          {
            name: "session-details-overview",
            path: "overview",
            component: () =>
              import(
                "@/view/components/Sessions/SessionDetailPage/SessionOverview.vue"
              ),
            props: true,
          },
          {
            name: "session-details-activities",
            path: "activities",
            component: () =>
              import(
                "@/view/components/Sessions/SessionDetailPage/SessionActivities.vue"
              ),
          },
          // {
          //   name: "session-details-network",
          //   path: "network",
          //   component: () =>
          //     import(
          //       "@/view/components/Sessions/SessionDetailPage/SessionNetwork.vue"
          //     ),
          // },
          {
            name: "session-details-timeline",
            path: "timeline",
            component: () =>
              import(
                "@/view/components/Sessions/SessionDetailPage/SessionTimeline.vue"
              ),
          },
          {
            name: "session-details-feedback",
            path: "feedback",
            component: () =>
              import(
                "@/view/components/Sessions/SessionDetailPage/SessionFeedback/index.vue"
              ),
            children: [
              {
                name: "session-feedback-detail",
                path: ":feedback_id",
                component: () =>
                  import(
                    "@/view/components/Sessions/SessionDetailPage/SessionFeedback/index.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "sessions",
        name: "app-sessions",
        component: () => import("@/view/pages/sessions/index.vue"),
      },
      {
        path: "dashboard",
        name: "app-dashboard",
        component: () => import("@/view/pages/app/dashboard/index.vue"),
        props: true,
      },
      {
        path: "",
        name: "app-releases",
        redirect: "/app/:app_id/releases",
        component: () => import("@/view/pages/app/releases/index.vue"),
        children: [
          {
            path: "releases",
            name: "app-release-index",
            component: () =>
              import("@/view/components/Releases/ReleaseList.vue"),
          },
          {
            path: "comments",
            name: "app-comments",
            component: () =>
              import("@/view/components/Comment/CommentsDataTable.vue"),
          },
          {
            path: "installs",
            name: "app-install",
            component: () =>
              import("@/view/components/App/Dashboard/InstallList.vue"),
          },
        ],
      },
      {
        name: "release-detail-page",
        path: "release/:release_id",
        component: () =>
          import("@/view/components/Releases/ReleaseDetailPage/Overview.vue"),
      },
      {
        name: "release-comment",
        path: "release/:release_id/comment/:comment_id",
        component: () =>
          import("@/view/components/Releases/ReleaseDetailPage/Overview.vue"),
      },
      {
        name: "release-comment-list",
        path: "release/:release_id/comments",
        component: () =>
          import("@/view/components/Releases/ReleaseDetailPage/Overview.vue"),
      },
      {
        name: "release-install",
        path: "release/:release_id/installs",
        component: () =>
          import("@/view/components/Releases/ReleaseDetailPage/Overview.vue"),
      },
      {
        path: "settings/members",
        name: "app-settings-members",
        component: () => import("@/view/components/App/Settings.vue"),
      },
      {
        path: "settings/invites",
        name: "app-settings-invite",
        component: () => import("@/view/components/App/Settings.vue"),
      },
      {
        path: "settings/releases",
        name: "app-settings-release",
        component: () => import("@/view/components/App/Settings.vue"),
      },
      {
        path: "settings/share",
        name: "app-settings-share",
        component: () => import("@/view/components/App/Settings.vue"),
      },
      {
        path: "settings/public-app-page",
        name: "app-settings-setting",
        component: () => import("@/view/components/App/Settings.vue"),
      },
      {
        path: "settings/information",
        name: "app-settings-information",
        component: () => import("@/view/components/App/Settings.vue"),
      },
      {
        path: "settings",
        name: "app-settings",
        component: () => import("@/view/components/App/Settings.vue"),
        redirect: "settings/information",
      },
      {
        path: "feedback",
        name: "app-feedback",
        component: () => import("@/view/components/App/Feedback/index.vue"),
        children: [
          {
            path: "",
            name: "app-feedback-index",
            component: () =>
              import("@/view/components/Feedback/FeedbackDataTable.vue"),
          },
          {
            path: "comments",
            name: "app-feedback-comments",
            component: () =>
              import("@/view/components/Comment/CommentsDataTable.vue"),
          },
        ],
      },
    ],
  },
];

const teamRoutes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/view/pages/user/dashboard/index.vue"),
    beforeEnter: chainGuards(checkKYC, verifyPartOfTeam),
  },
  {
    path: "/apps",
    name: "apps",
    component: () => import("@/view/pages/app/list.vue"),
  },
  {
    path: "/honest-feedback",
    name: "honest-feedback",
    component: () => import("@/view/pages/feedback/honest-feedback.vue"),
  },
  {
    path: "/team",
    name: "team",
    component: () => import("@/view/pages/team/index.vue"),
    redirect: "/team/members",
    children: [
      {
        path: "members",
        name: "team-members",
        component: () => import("@/view/components/Team/Members/List.vue"),
      },
      {
        path: "invites",
        name: "invites",
        component: () => import("@/view/pages/team/invites.vue"),
      },
      {
        path: "storage",
        name: "storage",
        component: () => import("@/view/components/Team/Storage/List.vue"),
      },
      {
        path: "settings",
        name: "team-setting",
        component: () => import("@/view/pages/team/settings.vue"),
      },
      {
        path: "plan",
        name: "team-plan",
        component: () => import("@/view/pages/team/plan.vue"),
      },
      {
        path: "/team/usage",
        name: "team-usage",
        component: () => import("@/view/pages/team/plan.vue"),
      },
      {
        path: "/team/transactions",
        name: "team-plan-history",
        component: () =>
          import("@/view/components/Team/TransactionHistory.vue"),
      },
      {
        path: "/team/integrations",
        name: "team-integrations",
        component: () => import("@/view/pages/app/integrations.vue"),
      },
      {
        path: "/team/integrations/:integration_id/",
        name: "integration-source",
        component: () => import("@/view/pages/team/integrations/detail.vue"),
      },
      {
        path: "/team/integrations/:integration_id/auth",
        name: "integration-source-auth",
        component: () => import("@/view/pages/team/integrations/detail.vue"),
      },
    ],
  },
  {
    path: "/team/timeline",
    name: "timeline",
    component: () => import("@/view/pages/team/index.vue"),
  },
  {
    path: "/team/integrations/testappio",
    name: "testappio",
    component: () => import("@/view/pages/team/integrations/testappio.vue"),
  },
  {
    path: "sessions/overview",
    name: "sessions",
    component: () => import("@/view/pages/sessions/index.vue"),
  },
  {
    path: "/sessions/applications",
    name: "session-applications",
    component: () => import("@/view/pages/sessions/applications.vue"),
  },
  {
    path: "/sessions/activities",
    name: "session-activities",
    component: () => import("@/view/pages/sessions/activities.vue"),
  },
  {
    path: "/sessions/feedback",
    name: "session-feedback",
    component: () => import("@/view/pages/sessions/feedback.vue"),
  },
  {
    path: "/app/sessions/user/:user_id",
    name: "app-session-user",
    component: () => import("@/view/pages/sessions/user.vue"),
  },
];

const authenticatedRoutes = [
  {
    path: "/",
    component: () => import("@/view/layout/Layout"),
    name: "auth",
    beforeEnter: chainGuards(
      isAuthenticated,
      fetchUserRoles,
      checkKYC,
      redirectToStoredURL,
      verifyPartOfTeam,
      checkMemberStatus
    ),
    children: [...welcomeRoutes, ...userRoutes, ...teamRoutes, ...appRoutes],
  },
];

const unAuthenticatedRoutes = [
  {
    path: "/auth",
    component: () => import("@/view/pages/user/auth/Auth"),
    beforeEnter: isAuthenticated,
    children: [
      {
        name: "signin",
        path: "/signin",
        component: () => import("@/view/pages/user/auth/Signin"),
        meta: {},
      },
      {
        name: "signup",
        path: "/signup",
        component: () => import("@/view/pages/user/auth/Signup"),
        meta: {
          title: "TestApp.io - Portal",
          description:
            "Sign up for TestApp.io and streamline your app testing. Share Android (APK) and iOS (IPA) applications with ease and receive immediate feedback from your team and testers",
        },
      },
      {
        name: "verify",
        path: "/verify",
        component: () => import("@/view/pages/user/auth/activate"),
      },
      {
        name: "forgot-password",
        path: "/forgot-password",
        component: () => import("@/view/pages/user/auth/ForgotPassword"),
      },
      {
        name: "reset-password",
        path: "/reset-password/:token",
        component: () => import("@/view/pages/user/auth/ResetPassword"),
      },
    ],
  },
];

const externalRoutes = [
  {
    path: "/activate/:token",
    name: "activate",
    component: () => import("@/view/pages/user/auth/ActivateAccount.vue"),
  },
  {
    path: "/verify/transfer-team-ownership/:token",
    name: "verify-token-params",
    component: () => import("@/view/pages/static/verify-token.vue"),
  },
  {
    path: "/i/:invite_id",
    name: "invite",
    component: () => import("@/view/pages/team/invite/index.vue"),
  },

  //START STATIC PAGES
  {
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/static/404.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/view/pages/static/error.vue"),
  },

  {
    path: "/notification-settings",
    name: "manage-notification",
    component: () =>
      import("@/view/pages/static/notification_settings/uuid/index.vue"),
  },
  {
    path: "/notification-settings/:uuid",
    name: "notification-setting",
    component: () =>
      import("@/view/pages/static/notification_settings/uuid/index.vue"),
  },
  {
    path: "/start",
    name: "start",
    component: () => import("@/view/pages/static/start.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/view/pages/static/contact.vue"),
  },
  {
    path: "/udid",
    name: "udid",
    component: () => import("@/view/pages/static/udid.vue"),
  },
  {
    path: "/install/:appID",
    name: "install-old",
    component: () => import("@/view/pages/static/install-old.vue"),
  },
  {
    path: "/apps/install/:uid",
    name: "install",
    component: () => import("@/view/pages/static/install.vue"),
  },

  {
    path: "/mobile-app/download",
    name: "external-download-app",
    component: () => import("@/view/pages/static/mobile-app/download.vue"),
  },
  {
    path: "/mobile-app/install",
    name: "install-app",
    component: () => import("@/view/pages/static/mobile-app/install.vue"),
  },
  {
    path: "/mobile-app/check",
    name: "mobile-app-check",
    component: () => import("@/view/pages/static/mobile-app/check.vue"),
  },
  {
    path: "/mobile-app/update",
    name: "update-app",
    component: () => import("@/view/pages/static/mobile-app/update.vue"),
  },
  {
    path: "/mobile-app/update/android",
    name: "update-android-app",
    component: () => import("@/view/pages/static/mobile-app/update.vue"),
  },
  {
    path: "/mobile-app/update/ios",
    name: "update-ios-app",
    component: () => import("@/view/pages/static/mobile-app/update.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
  /*
   *************
   ** Express **
   *************
   */
  // {
  //   path: "/express/external",
  //   name: "external-upload",
  //   meta: {
  //     title: "TestApp.io - Portal Express",
  //     description:
  //       "With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!"
  //   },
  //   component: () => import("@/view/pages/express/single-upload.vue")
  // },
];

export default new Router({
  routes: [...authenticatedRoutes, ...unAuthenticatedRoutes, ...externalRoutes],
  mode: "history",
});
