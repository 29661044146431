import ApiService from "@/core/services/api.service";
import { isEmpty, get } from "@/core/services/helper.service";

// action types

export const TIMELINE = "timeline";

// mutation types
export const SET_TIMELINE = "timeline";
export const CLEAR_TIMELINE_DATA = "clearTimelineData";

const state = {
  timeline: [],
  totalTimelineList: [],
  totalTimelineCount: "",
  timelineLoading: true,
};

const getters = {
  getTotalTimeLineCount(state) {
    return state.totalTimelineCount;
  },
  getTimeline(state) {
    return state.timeline;
  },
  getTotalTimelineList(state) {
    return state.totalTimelineList;
  },
};

const actions = {
  [TIMELINE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/core/app/${params.app_id}/timeline?lastID=${params.lastId || ""}`
      )
        .then(({ data }) => {
          let response = data.data;
          let timeline = get(response[0], "data", []);
          if (params.lastId) {
            context.commit("setTimelinePaginatedData", timeline);
          } else {
            context.commit(SET_TIMELINE, timeline);
            context.commit(
              "setTotalTimelineCount",
              get(response[0], "meta.total", 0)
            );
          }
          // this will add the timeline data and stop the loading
          resolve(data);
        })
        .catch((error) => {
          // this not gonna set any data just stop the loading, check this muttation out
          context.commit(SET_TIMELINE);
          reject(error);
        });
    });
  },
};

const mutations = {
  setTotalTimelineCount(state, payload) {
    state.totalTimelineCount = payload;
  },
  setTimelinePaginatedData(state, data) {
    let timeline = state.totalTimelineList.length
      ? state.totalTimelineList
      : state.timeline;
    timeline = [...timeline, ...data];
    state.timeline = [...data];
    state.totalTimelineList = [...timeline];
  },
  setPreviousTimelineData(state, data) {
    state.timeline = [...data];
  },
  [SET_TIMELINE](state, timeline) {
    if (!isEmpty(timeline)) {
      state.timeline = timeline || [];
      state.totalTimelineList = timeline || [];
    }
    state.timelineLoading = false;
  },
  [CLEAR_TIMELINE_DATA](state) {
    state.timeline = [];
    state.totalTimelineCount = 0;
    state.totalTimelineList = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
