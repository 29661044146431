import env from "@/core/env";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";
import store from "@/store";
import { get } from "@/core/services/helper.service";
import { CLEAR_STORE, REFRESH_TOKEN } from "@/store/users/auth.module";
import router from "@/router";
import Vue from "vue";

const handleResponse = (response) => response;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// TODO: refactor all this bs
const handleApiError = (error) => {
  // throw new CustomError(get(error, "response.data", error));
  return Promise.reject({
    status: get(error, "response.status", error),
    ...get(error, "response.data", error),
  });
  // return get(error, "response.data", error);
};
const handleError = async (error) => {
  const originalRequest = error.config;

  if (!error.response && !navigator.onLine) {
    let networkError = `<div>
          <p>Connection to the server was unsuccessful maybe due to one or all the following reasons:</p>
          <p>- Our servers are not reachable at this moment</p>
          <p>- Too many requests: Slow down and check again after few minutes</p>
          <p>- Unhealthy IP address or Tor or similar connections</p>
          <div>If you need more help, please do contact us</div>
         </div>`;
    store.commit("notifyErrorMessage", networkError);
    store.commit("setIsNetworkError", networkError);
    return Promise.reject({
      message: networkError,
    });
  }

  if (get(error, "response.status", "") !== 471) {
    store.commit("setInactiveUserMessage", null);
  }

  if (get(error, "response.status", "") === 471) {
    let message = get(
      error,
      "response.data.message",
      "Your membership in this team is currently inactive. Please contact the owners or admins of this team for more information"
    );

    store.commit("setInactiveUserMessage", message);
  }

  if (get(error, "response.status", "") === 470) {
    store.dispatch(CLEAR_STORE);
  }
  if (
    get(error, "response.status", "") === 401 &&
    error.config.url.indexOf("refresh-token") != -1
  ) {
    processQueue(error, null);
    isRefreshing = false;
    return Promise.reject(error);
  }

  if (get(error, "response.status", "") === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      try {
        const token = await new Promise((resolve, reject) => {
          failedQueue.push({
            resolve,
            reject,
          });
        });
        originalRequest.headers["Authorization"] = "Token " + token;
        return await axios(originalRequest);
      } catch (err) {
        return await Promise.reject(err);
      }
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      store
        .dispatch(REFRESH_TOKEN)
        .then((response) => {
          JwtService.saveToken(response);
          Vue.axios.defaults.headers.common["Authorization"] =
            "Token " + response.access_token;
          originalRequest.headers["Authorization"] =
            "Token " + response.access_token;
          processQueue(null, response.access_token);
          isRefreshing = false;
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          JwtService.destroyToken();
          if (!["install", "invite"].includes(router.currentRoute.name)) {
            store.commit("setRedirectionUrl", {
              name: router.currentRoute.name,
              query: router.currentRoute.query,
              params: router.currentRoute.params,
            });
            setTimeout(() => {
              router
                .push({
                  name: "signin",
                })
                .catch(() => {});
            }, 1000);
          } else {
            store.commit("setOpenSigninProcess", true);
          }
          if (
            router &&
            router.currentRoute &&
            router.currentRoute.query &&
            router.currentRoute.query.team_id
          ) {
            store.commit("setQueryTeamId", router.currentRoute.query.team_id);
          }
          isRefreshing = false;
          reject(err);
        });
    });
  }

  return handleApiError(error);
};

const _castle = (window._castle = window._castle || null);

const headers = {
  Authorization: `Token ${JwtService.getToken()}`,
  "Portal-Version-Code": env.VUE_APP_RELEASE_CODE,
  "Portal-Version": env.VUE_APP_RELEASE,
  "X-Castle-Client-Id": _castle ? _castle("getClientId") : null,
};

export const coreApi = axios.create({
  baseURL: env.VUE_APP_API_URL_CORE,
  headers,
});
coreApi.interceptors.response.use(handleResponse, handleError);

export const portalApi = axios.create({
  baseURL: env.VUE_APP_API_URL_PORTAL,
  headers,
});
portalApi.interceptors.response.use(handleResponse, handleError);

export default {
  install(Vue) {
    Vue.prototype.$coreApi = coreApi;
    Vue.prototype.$portalApi = portalApi;
  },
};
