import Vue from "vue";
import Vuex from "vuex";

import app from "@/store/apps/app.module";
import apps from "@/store/apps/apps.module";
import releases from "@/store/releases/releases.module.js";
import team from "@/store/team/team.module";
import auth from "@/store/users/auth.module";
import util from "@/store/common/util.module";
import invite from "@/store/team/invite.module";
import config from "@/store/common/config.module";
import storage from "@/store/team/storage.module";
import contact from "@/store/users/contact.module";
import uploads from "@/store/common/uploads.module";
import createApp from "@/store/apps/createApp.module";
import dashboard from "@/store/users/dashboard.module";
import timeline from "@/store/apps/timeline.module.js";
import manageTeam from "@/store/team/manageTeam.module";
import members from "@/store/team/members.module.js";
import notifications from "@/store/users/notifications.module";
import settings from "@/store/users/settings.module.js";
import appMember from "@/store/apps/appmember.module.js";
import createRelease from "@/store/releases/createRelease.module.js";
import expressRelease from "@/store/releases/expressRelease.module.js";
import installRelease from "@/store/releases/install.module.js";
import whatsnew from "@/store/users/whatsNew.module.js";
import integrations from "@/store/team/integrations.module.js";
import appChat from "@/store/apps/chat.module.js";
import subscribers from "@/store/apps/subscribers.module.js";
import teamTimeline from "@/store/team/timeline.module.js";
import onboardingQuestion from "@/store/users/kyc.module";
import sessions from "@/store/apps/sessions.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    app,
    apps,
    timeline,
    util,
    team,
    config,
    invite,
    storage,
    uploads,
    contact,
    createApp,
    dashboard,
    manageTeam,
    members,
    notifications,
    settings,
    appMember,
    releases,
    createRelease,
    expressRelease,
    installRelease,
    whatsnew,
    integrations,
    appChat,
    onboardingQuestion,
    teamTimeline,
    subscribers,
    sessions,
  },
});
