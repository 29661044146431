import * as tus from "tus-js-client";
import store from "@/store/index";
import {
  SET_UPLOADING_PROGRESS,
  MARK_AS_UPLOADED,
  SET_FAILED,
} from "@/store/common/uploads.module";
import { segmentEvents } from "@/consts/segmentEventConst";

export const upload = (
  file,
  endpoint,
  token,
  action = "express",
  type = "general",
  id,
  uploadDetail
) => {
  // const parallelUploads = 2;

  let tusUpload = new tus.Upload(file, {
    chunkSize: 50 * 1024 * 1024,
    endpoint: endpoint,
    // parallelUploads,
    retryDelays: [0, 3000, 5000, 10000, 20000],
    overridePatchMethod: false,
    metadata: {
      token: token,
      action: action,
      filename: file.name,
      filetype: file.type,
    },
    onError: function(error) {
      let message = error?.message
        ?.substring(
          error.message.lastIndexOf(":") + 2,
          error.message.lastIndexOf('"')
        )
        ?.trim();
      if (
        error &&
        error.message &&
        error.message.includes("response text: n/a")
      ) {
        message = "Check your internet connection and try again.";
      }
      store.commit("notifyErrorMessage", {
        message: message || "Something went wrong, please try again later",
      });
      store.commit("logError", error);
      store.commit("logEvent", {
        eventName: segmentEvents.RELEASE_ERROR,
        message: { message: message },
      });
      store.commit(SET_FAILED, { type: type, error: { message } });
    },
    onProgress: function(bytesUploaded, bytesTotal) {
      let percentage = Math.floor((bytesUploaded / bytesTotal) * 100);
      // let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
      store.commit(SET_UPLOADING_PROGRESS, {
        id: id,
        uploadDetail: uploadDetail,
        type: type,
        totalUploaded: bytesUploaded,
        progress: percentage > 99 ? 99 : percentage,
        name: file.name,
        size: file.size,
      });
    },
    onShouldRetry: function(err) {
      let status = err.originalResponse ? err.originalResponse.getStatus() : 0;

      store.commit("logEvent", {
        eventName: segmentEvents.RELEASE_RETRY,
        message: { status: status, err: err },
      });

      // If the status is a 403, we do not want to retry.
      if (status === 403) {
        return false;
      }

      // For any other status code, tus-js-client should retry.
      return true;
    },
    onSuccess: function() {
      // store.commit("notifyUserMessage", {
      //   message: "Successfully uploaded file",
      // });
      store.commit(MARK_AS_UPLOADED, { type: type, id: id });
      return "success";
    },
  });

  function startOrResumeUpload(tusUpload) {
    // Check if there are any previous uploads to continue.
    tusUpload.findPreviousUploads().then(function(previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        // window.analytics.track("Release resumed");
        // store.commit("notifyInfoMessage", {
        //   message: "Upload resumed to a new release",
        // });
        tusUpload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      tusUpload.start();
    });
  }

  // // Add listeners for the pause and unpause button
  // var pauseButton = document.querySelector("#pauseButton");
  // var unpauseButton = document.querySelector("#unpauseButton");

  // pauseButton.addEventListener("click", function() {
  //   tusUpload.abort();
  // });

  // unpauseButton.addEventListener("click", function() {
  //   startOrResumeUpload(tusUpload);
  // });

  // Start the upload by default
  startOrResumeUpload(tusUpload);
};
