import ApiService from "@/core/services/api.service";
import { getAPIFullURL } from "@/core/services/helper.service";
import apiRoutes from "@/consts/routes/apiRoutes";
import { getQuery } from "@/core/utilities";
// import { isEmpty, get } from "@/core/services/helper.service";
export const GET_ALL_SESSIONS = "getAllSessions";
export const GET_APP_SESSIONS = "getAppSessions";
export const GET_SESSIONS_DETAIL = "getSessionsDetail";
export const GET_SESSION_ACTIVITIES = "getSessionActivities";
export const GET_SESSION_PERFORMANCE = "getSessionPerformance";
export const GET_FEEDBACK_SESSIONS = "getFeedbackSessions";
export const GET_SESSIONS_FEEDBACK = "getSessionsFeedback";
export const GET_ALL_SESSION_USER = "getAllSessionUser";
export const GET_USER_SESSION = "getUserSession";
export const GET_USER_SESSION_PERFORMANCE = "getUserSessionPerformance";
export const GET_USER_SESSION_ACTIVITIES = "getUserSessionActivities";
export const GET_SESSION_DEVICES = "getSessionDevices";
export const GET_SESSION_APPLICATIONS = "getSessionApplications";

export const GET_SESSION_DETAILS = "getSessionDetails";

const state = {
  sessionDetailsLoading: true,
  showSessionDetails: false,
  // session User
  allSessionUser: [],
  allTeamSession: [],
  totalUserList: [],
  totalUserCount: 0,

  // session Activities
  allSessionActivities: [],
  totalActivitiesList: [],
  totalActivityCount: 0,

  // session Devices

  allSessionDevices: [],
  totalDevicesList: [],
  totalDevicesCount: 0,

  // session Application

  allSessionApplication: [],
  totalApplicationList: [],
  totalApplicationCount: 0,

  // session feedback

  allSessionFeedback: [],
  totalFeedbackList: [],
  totalFeedbackCount: 0,

  sessionDetails: null,
};

const getters = {
  // user activities

  getAllSessionActivities(state) {
    return state.allSessionActivities;
  },
  getTotalSessionActivities(state) {
    return state.totalActivitiesList;
  },
  getTotalActivityCount(state) {
    return state.totalActivityCount;
  },

  // session user
  getAllSessionUser(state) {
    return state.allSessionUser;
  },
  getTotalSessionUser(state) {
    return state.totalUserList;
  },
  getTotalUserCount(state) {
    return state.totalUserCount;
  },
  // Session devices
  getAllSessionDevices(state) {
    return state.allSessionDevices;
  },
  getTotalSessionDevices(state) {
    return state.totalDevicesList;
  },
  getTotalDevicesCount(state) {
    return state.totalDevicesCount;
  },
  // Session applications
  getAllSessionApplication(state) {
    return state.allSessionApplication;
  },
  getTotalSessionApplication(state) {
    return state.totalApplicationList;
  },
  getSessionApplicationCount(state) {
    return state.totalApplicationCount;
  },

  // Session feedbacks
  getAllSessionFeedback(state) {
    return state.allSessionFeedback;
  },
  getTotalSessionFeedback(state) {
    return state.totalFeedbackList;
  },
  getSessionFeedbackCount(state) {
    return state.totalFeedbackCount;
  },

  getSessionDetails(state) {
    return state.sessionDetails;
  },
  getSessionDetailsLoading(state) {
    return state.sessionDetailsLoading;
  },
};

const mutations = {
  toggleSessionDetails(state, payload) {
    state.showSessionDetails = payload;
  },
  // session users

  setAllSessionUser(state, payload) {
    state.allSessionUser = payload;
    state.totalUserList = payload;
  },
  setPaginatedUserData(state, payload) {
    let userList = state.totalUserList.length
      ? state.totalUserList
      : state.allSessionUser;
    userList = [...userList, ...payload];
    state.totalUserList = userList;
    state.allSessionUser = payload;
  },
  setPreviousUserData(state, payload) {
    state.allSessionUser = [...payload];
  },
  setTotalUserCount(state, payload) {
    state.totalUserCount = payload;
  },
  // session Activities
  setAllActivities(state, payload) {
    state.allSessionActivities = payload;
    state.totalActivitiesList = payload;
  },
  setPaginatedActivitiesData(state, payload) {
    let activityList = state.totalActivitiesList.length
      ? state.totalActivitiesList
      : state.allSessionActivities;
    activityList = [...activityList, ...payload];
    state.totalActivitiesList = activityList;
    state.allSessionActivities = payload;
  },
  setPreviousActivitiesData(state, payload) {
    state.allSessionActivities = [...payload];
  },
  setTotalActivitiesCount(state, payload) {
    state.totalActivityCount = payload;
  },
  // session Devices
  setAllDevicesUser(state, payload) {
    state.allSessionDevices = payload;
    state.totalDevicesList = payload;
  },
  setPaginatedDevicesData(state, payload) {
    let deviceList = state.totalDevicesList.length
      ? state.totalDevicesList
      : state.allSessionDevices;
    deviceList = [...deviceList, ...payload];
    state.totalDevicesList = deviceList;
    state.allSessionDevices = payload;
  },
  setPreviousDevicesData(state, payload) {
    state.allSessionDevices = [...payload];
  },
  setTotalDevicesCount(state, payload) {
    state.totalDevicesCount = payload;
  },
  // session application
  setAllSessionApplication(state, payload) {
    state.allSessionApplication = payload;
    state.totalApplicationList = payload;
  },
  setPaginatedApplicationData(state, payload) {
    let applicationList = state.totalApplicationList.length
      ? state.totalApplicationList
      : state.allSessionApplication;
    applicationList = [...applicationList, ...payload];
    state.totalApplicationList = applicationList;
    state.allSessionApplication = payload;
  },
  setPreviousApplicationData(state, payload) {
    state.allSessionApplication = [...payload];
  },
  setTotalApplicationCount(state, payload) {
    state.totalApplicationCount = payload;
  },

  // session feedback
  setAllSessionFeedback(state, payload) {
    state.allSessionFeedback = payload;
    state.totalFeedbackList = payload;
  },
  setPaginatedFeedbackData(state, payload) {
    let feedbackList = state.totalFeedbackList.length
      ? state.totalFeedbackList
      : state.allSessionFeedback;
    feedbackList = [...feedbackList, ...payload];
    state.totalFeedbackList = feedbackList;
    state.allSessionFeedback = payload;
  },
  setPreviousFeedbackData(state, payload) {
    state.allSessionFeedback = [...payload];
  },
  setTotalFeedbackCount(state, payload) {
    state.totalFeedbackCount = payload;
  },

  setSessionDetails(state, payload) {
    state.sessionDetails = payload;
  },
  setSessionDetailsLoading(state, payload) {
    state.sessionDetailsLoading = payload;
  },
};

const actions = {
  [GET_ALL_SESSIONS](context, params) {
    let url = getAPIFullURL("/core/sessions", params);
    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_APP_SESSIONS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/core/app/${params.app_id}/sessions?last_timestamp=${params?.timestamp}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SESSIONS_DETAIL](context, params) {
    return new Promise((resolve, reject) => {
      const query = getQuery({
        last_timestamp: params?.timestamp,
      });
      ApiService.get(
        `/core/app/${params.app_id}/session/${params.session_id}${
          query ? `?${query}` : ""
        }`
      )
        .then(({ data }) => {
          context.commit("setSessionDetails", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("setSessionDetailsLoading", false);
        });
    });
  },
  [GET_SESSION_ACTIVITIES](context, params) {
    const query = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) => {
      this.$coreApi
        .get(`${apiRoutes.core.session.allActivities}?${query}`)
        .then(({ data }) => {
          if (params.last_timestamp) {
            context.commit("setPaginatedActivitiesData", data.data);
          } else {
            context.commit("setAllActivities", data.data);
            context.commit("setTotalActivitiesCount", data.meta.total);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SESSION_DEVICES](context, params) {
    // app_id, user_id, sdk_app_id, last_id
    let url = getAPIFullURL("/core/session/devices", params);

    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then(({ data }) => {
          if (params.last_id) {
            context.commit("setPaginatedDevicesData", data.data);
          } else {
            context.commit("setAllDevicesUser", data.data);
            context.commit("setTotalDevicesCount", data.meta.total);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SESSION_APPLICATIONS](context, params) {
    // app_id, last_id
    let url = getAPIFullURL("core/session/applications", params);

    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then(({ data }) => {
          if (params.last_id) {
            context.commit("setPaginatedApplicationData", data.data);
          } else {
            context.commit("setAllSessionApplication", data.data);
            context.commit("setTotalApplicationCount", data.meta.total);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SESSION_PERFORMANCE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/session/performance?appID=${params.app_id}&session_id=${params.session_id}&last_timestamp=${params.timestamp}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_FEEDBACK_SESSIONS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${params.app_id}/session/${
          params.session_id
        }/feedback?last_id=${params.lastID || ""}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SESSIONS_FEEDBACK](context, params) {
    return new Promise((resolve, reject) => {
      // app_id, session_id, release_id, last_id
      let url = getAPIFullURL("core/session/feedback", params);
      ApiService.get(url)
        .then(({ data }) => {
          if (params.last_id) {
            context.commit("setPaginatedFeedbackData", data.data);
          } else {
            context.commit("setAllSessionFeedback", data.data);
            context.commit("setTotalFeedbackCount", data.meta.total);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_USER_SESSION_PERFORMANCE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/sessions/user/${params.user_id}/performance?last_timestamp=`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_ALL_SESSION_USER](context, params) {
    // app_id, last_id
    let url = getAPIFullURL("core/session/users", params);
    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then(({ data }) => {
          if (params.lastID) {
            context.commit("setPaginatedUserData", data.data);
          } else {
            context.commit("setAllSessionUser", data.data);
            context.commit("setTotalUserCount", data.meta.total);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_USER_SESSION_ACTIVITIES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/sessions/user/${params.user_id}/activities?last_timestamp=`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_USER_SESSION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/sessions/user/${params.user_id}?last_timestamp=`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
