import ApiService from "@/core/services/api.service";
export const GET_KYC_LIST = "getKYCList";
export const UPDATE_KYC_QUESTION = "updateKYCQuestion";

const state = {};
const getters = {};
const actions = {
  [GET_KYC_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("core/kyc", payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  [UPDATE_KYC_QUESTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`core/kyc/${payload.id}`, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations,
};
