import Vue from "vue";
import {
  getRelativeOrAbsoluteTime,
  getUnixTime,
} from "@/core/services/helper.service";
import dayjs from "dayjs";

Vue.filter("relativeDate", getRelativeOrAbsoluteTime);

Vue.filter("unix", (value) => getUnixTime(value));

Vue.filter("firstLetter", (value) => {
  return value.charAt(0).toUpperCase();
});

Vue.filter("actualDate", (value) => {
  return dayjs(value).format("MMM D, YYYY [at] HH:mm");
});

Vue.filter("sum", (value) => {
  return value.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
});

const formatter = new Intl.NumberFormat("en-US");
Vue.filter("formatNumber", (value) => formatter.format(value));
