import ApiService from "@/core/services/api.service";
// import { isEmpty, get } from "@/core/services/helper.service";

// action types

export const GET_APP_MESSAGES = "getAppMessages";
export const SEND_MESSAGE = "sendMessage";
export const EDIT_MESSAGE = "editMessage";
export const DELETE_MESSAGE = "deleteMessage";

const state = {
  appMessages: [],
  totalAppMessage: 0,
};

const getters = {
  getAppMessages(state) {
    return state.appMessages;
  },
  getTotalAppMessageCount(state) {
    return state.totalAppMessage;
  },
};

const actions = {
  [GET_APP_MESSAGES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/core/app/${params.app_id}/chat?lastID=${params.lastID || ""}`
      )
        .then(({ data }) => {
          let response = data.data[0].data;
          let totalData = data.data[0].meta;
          if (params.lastID) {
            context.commit("setMessageResponse", response);
          } else {
            context.commit("setAppMessages", response);
            context.commit("setTotalAppMessageCount", totalData.total);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_MESSAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/app/${payload.app_id}/chat`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_MESSAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `/core/app/${params.app_id}/chat/message/${params.chat_id}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [EDIT_MESSAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/core/app/${payload.app_id}/chat/message/${payload.chat_id}`,
        { message: payload.message }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setAppMessages(state, payload) {
    let messages = payload.reverse();
    state.appMessages = messages;
  },
  setTotalAppMessageCount(state, payload) {
    state.totalAppMessage = payload;
  },
  setMessageResponse(state, payload) {
    if (state.appMessages && state.appMessages.length) {
      let reversePayload = payload.reverse();
      state.appMessages = [...reversePayload, ...state.appMessages];
    } else {
      state.appMessages = [...payload].reverse();
    }
  },
  setNewAppMessage(state, payload) {
    const data = state.appMessages.find((message) => message.id === payload.id);
    if (!data) {
      state.appMessages.push(payload);
    }
  },
  removeDeletedMessage(state, messageID) {
    state.appMessages = state.appMessages.filter(
      (message) => message.id !== messageID
    );
  },
  updateMessage(state, payload) {
    let allMessage = [];
    allMessage = state.appMessages.map((message) => {
      if (message.id === payload.id) {
        message.message = payload.message;
      }
      return message;
    });
    state.appMessages = [...allMessage];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
