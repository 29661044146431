var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "background"
  }, [_c('v-theme-provider', {
    attrs: {
      "root": ""
    }
  }, [_c('router-view')], 1), _c('vue-snack-bar'), _c('snackbar'), _c('modal', {
    attrs: {
      "hide-close": _vm.getPortalUpdateInfo && _vm.getPortalUpdateInfo.versionInfo && _vm.getPortalUpdateInfo.versionInfo.force
    },
    on: {
      "close": function close($event) {
        _vm.showReleaseUpdate = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "bold-text pb-0 font-size-h3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" New release ready ")]), _c('v-col', {
          staticClass: "font-size-h4",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_vm._v(" New version "), _c('b', [_vm._v(_vm._s("".concat(_vm.getPortalUpdateInfo && _vm.getPortalUpdateInfo.versionInfo ? _vm.getPortalUpdateInfo.versionInfo.version : "")))]), _vm._v(" (released "), _vm.getPortalUpdateInfo && _vm.getPortalUpdateInfo.versionInfo ? _c('vue-hoverable-date', {
          attrs: {
            "date": _vm.getPortalUpdateInfo.versionInfo.date || _vm.getPortalUpdateInfo.versionInfo.update_time
          }
        }) : _vm._e(), _vm._v(" ) ")], 1), _c('div', {
          staticClass: "my-4"
        }, [_vm._v(" You're using "), _c('b', [_vm._v(" " + _vm._s("".concat(_vm.currentVersion.split("@")[1])))]), _vm._v(" (released "), _c('vue-hoverable-date', {
          attrs: {
            "date": _vm.currentReleaseTimestamp
          }
        }), _vm._v(" ) ")], 1), _c('div', {
          staticClass: "mb-3"
        }, [_vm._v(" We're ready to load new version, and will bring you right back to this page in few seconds. ")])]), _c('v-col', {
          staticClass: "text-right pb-0",
          attrs: {
            "cols": "12"
          }
        }, [_vm.getPortalUpdateInfo && _vm.getPortalUpdateInfo.versionInfo ? _c('v-btn', {
          staticClass: "text-transform-none mx-3",
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.refresh
          }
        }, [_vm._v(" " + _vm._s(_vm.getPortalUpdateInfo.versionInfo.force ? "Reload to update" : "I'm Ready!") + " ")]) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showReleaseUpdate,
      callback: function callback($$v) {
        _vm.showReleaseUpdate = $$v;
      },
      expression: "showReleaseUpdate"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-3"
        }, [_c('v-row', [_c('v-col', {
          staticClass: "my-4 font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Switching teams ")]), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": ""
          }
        })], 1), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v("Please wait...")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showSwitchModal,
      callback: function callback($$v) {
        _vm.showSwitchModal = $$v;
      },
      expression: "showSwitchModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }