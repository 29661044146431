import { isEmpty, get } from "@/core/services/helper.service";
import ApiService from "@/core/services/api.service";
import router from "@/router";

// action types
export const DASHBOARD = "dashboard";
export const CLEAR_DASHBOARD = "clearDashboard";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SEND_FEEDBACK = "sendFeedback";

// mutation types
export const SET_DASHBOARD = "dashbaord";
export const CLEAR_DASHBOARD_DATA = "clearDashboardData";

const state = {
  usage: {},
  currentPlanUsage: 0,
  dashboardWidgets: {
    recentFeedback: [],
    recentMembers: [],
    recentApps: [],
    recentReleases: [],
    recentComments: [],
    recentActivities: [],
    recentSessions: [],
  },
  inviteDrawer1: false,
  inviteDrawer2: false,
  dashboardLoader: false,
};

const getters = {
  getDashboardLoader(state) {
    return state.dashboardLoader;
  },
  getDashboardWidgets(state) {
    return state.dashboardWidgets;
  },
};

const actions = {
  [GET_NOTIFICATIONS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("core/notifications")
        .then(({ data }) => {
          let invite = [];
          let alert = [];
          let system = [];
          data.data.map((v) =>
            v.data.map((v) => {
              if (v.action == "invite") {
                invite.push(v);
              }
              if (v.action == "alert") {
                alert.push(v);
              }
              if (v.action == "system") {
                system.push(v);
              }
            })
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("core/feedback", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DASHBOARD](context) {
    context.commit("setDashboardLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("/portal/dashboard")
        .then(({ data }) => {
          context.commit("setDashboardWidget", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("setDashboardLoader", false);
        });
    });
  },
  [CLEAR_DASHBOARD](context) {
    context.commit(CLEAR_DASHBOARD_DATA);
  },
};

const mutations = {
  setApps(state, apps) {
    state.apps.push(apps);
  },
  [SET_DASHBOARD](state) {
    state.dashboardLoader = false;
  },
  setDashboardWidget(state, data) {
    state.dashboardLoader = false;
    state.dashboardWidgets.recentComments = data?.release_comments || [];
    state.dashboardWidgets.recentFeedback = data?.feedback || [];
    state.dashboardWidgets.recentApps = data?.apps || [];
    state.dashboardWidgets.recentMembers = data?.team_members || [];
    state.dashboardWidgets.recentReleases = data?.releases || [];
    state.dashboardWidgets.recentSessions = data?.sessions || [];
  },
  setDashboardLoader(state, data) {
    state.dashboardLoader = data || false;
  },
  [CLEAR_DASHBOARD_DATA](state) {
    state.total_releases = 0;
    state.total_feedback = 0;
    state.total_team_members = 0;
    state.total_apps = 0;
  },
  setInviteDrawer1(state, v) {
    state.inviteDrawer1 = v;
  },
  setInviteDrawer2(state, v) {
    state.inviteDrawer2 = v;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
