import ApiService from "@/core/services/api.service";

// action types
export const SET_APP = "setApp";
export const IMAGE = "image";
export const CREATE = "createApp";
export const CLEAR_CREATE_APP = "clearNewApp";
export const GET_STARTED_INFO = "getStartedInfo";
// mutation types
export const UPDATE_APP = "updateApp";
export const CLEAR_CREATE_APP_DATA = "clearNewAPPData";

const state = {
  info: {},
  apkFileInfo: {},
  ipaFileInfo: {},
  openSigninProcess: false,
  releaseImage: {
    iosIcon: "",
    androidIcon: "",
  },
  socketStatus: false,
  isUploadingProgress: false,
};

const getters = {
  getNewAppInfo(state) {
    return state.info;
  },
  getOpenSigninProcess(state) {
    return state.openSigninProcess;
  },
};

const actions = {
  [CREATE](context, info) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/app`, info)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [IMAGE](context, params) {
    let { id, image } = params;
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/app/${id}/image`, image)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_STARTED_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`core/get-started`, payload)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SET_APP](context, info) {
    context.commit(UPDATE_APP, info);
  },
  [CLEAR_CREATE_APP](context) {
    context.commit(CLEAR_CREATE_APP_DATA);
  },
};

const mutations = {
  setOpenSigninProcess(state, payload) {
    state.openSigninProcess = payload || false;
  },
  [UPDATE_APP](state, info) {
    state.info = { ...state.info, ...info };
  },
  [CLEAR_CREATE_APP_DATA](state) {
    state.info = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
