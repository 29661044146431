import ApiService from "@/core/services/api.service";
import { get, getAPIFullURL } from "@/core/services/helper.service";

// action types
export const RELEASES = "getReleasesList";
export const DELETE_RELEASE = "deleteRelease";
export const ARCHIVE_RELEASE = "archiveRelease";
export const ARCHIVE_RELEASE_SCHEDULE = "archiveReleaseSchedule";
export const ARCHIVE_TEAM_RELEASE_SCHEDULE = "archiveTeamReleaseSchedule";
export const UPDATE_RELEASE = "updateRelease";
export const UPDATE_BULK_RELEASES = "updateBulkReleases";

export const GET_RELEASE_INFO = "getReleaseInfo";
export const MOVE_RELEASE = "moveRelease";
export const GET_RELEASE_AUTOCOMPLETE = "getReleaseAutoComplete";
export const GET_RELEASE_INITIAL_LIST = "getReleaseInitialList";
export const CREATE_SHAREABLE_LINK = "createShareableLink";
export const GET_SHARE_LINK_LIST = "getShareLinkList";
// export const CHECK_SHARE_LINK = "checkShareLink";
export const GET_SHARE_LINK_STATS = "getShareLinkStats";
export const UPDATE_SHARE_LINK_STATUS = "updateShareLinkStatus";
export const GET_SHARE_CHART_LIST = "getShareChartList";
export const UPDATE_APP_PUBLIC_STATUS = "updateAppPublicStatus";
export const GET_SHARE_VIEWS_LIST = "getShareViewsList";
export const GET_SHARE_INSTALL_LIST = "getShareInstallList";
export const DELETE_SHAREABLE_LINK = "deleteShareableLink";
export const UPDATE_SHAREABLE_LINK = "updateShareableLink";
export const VALIDATE_TESTFLIGHT_LINK = "validateTestflightLink";

// mutation types
export const REMOVE_RELEASE = "removeRelease";
export const RELEASE_DELETED = "releaseDeleted";
export const RELEASE_UPDATED = "releaseUpdated";
export const GET_RELEASE_MEMBER = "getReleaseMember";
export const CLEAR_RELEASE_DATA = "clearReleaseData";
export const SEND_INSTALL_FEEDBACK = "sendInstallFeedback";
export const SEND_RELEASE_FEEDBACK = "sendReleaseFeedback";
export const GET_RELEASE_INSTALL_FEEDBACK = "getReleaseInstallFeedback";
export const GET_INDIVIDUAL_RELEASE_INSTALL_FEEDBACK =
  "getIndividualReleaseInstallFeedback";
export const GET_FEEDBACK_ATTACHMENT = "getFeedbackAttachment";
export const UPDATE_FEEDBACK_ATTACHMENT = "updateFeedbackAttachment";
export const DELETE_FEEDBACK_ATTACHMENT = "deleteFeedbackAttachment";
export const UPDATE_FEEDBACK_INTERNAL_NOTES = "updateFeedbackInternalNotes";
export const UPDATE_FEEDBACK_STATUS = "updateFeedbackStatus";
export const UPDATE_FEEDBACK_DETAIL = "updateFeedbackDetail";
export const DELETE_INSTALL_FEEDBACK = "deleteInstallFeedback";

const state = {
  releases: [],
  totalReleasesData: [],
  releaseDetailErrorMessage: null,
  releaseDetail: {},
  releaseMeta: {},
  commentAttachmentSocketData: {},
  releaseMessage: "",
  releaseInfo: {},
  attachmentsProgressList: [],
  attachmentsList: [],
  attachmentThumbnailList: [],
  dataFromSocket: null,
  reloadReleasePage: false,
  dataFromFeedbackSocket: [],
  releaseLoader: true,
  releaseInstallFeedback: [],
  totalReleaseInstallFeedback: [],
  totalReleaseInstallFeedbackCount: null,
  feedbackPageMessage:
    "List of feedback provided from both our app (internal testers) and the public install page (external testers)",
  releaseAutocomplete: [],
  releaseInitialList: [],
  shareableLinks: [],
  totalShareableLinksCount: "",
  totalShareableLinks: [],
  viewsList: [],
  totalViewsList: [],
  totalViewsCount: "",
  installList: [],
  showLoader: false,
  totalInstallList: [],
  totalInstallCount: "",
  latestAndroidFeedback: null,
  latestIosFeedback: null,
  activeFeedbackId: "",
  latestAndroidFeedbackCount: "",
  latestIosFeedbackCount: "",
  shareStatsCount: {},
  // comments state
  totalReleaseComments: [],
  allReleaseComments: [],
  totalCommentCount: 0,
  // End
  refreshRelease: false,
};

const getters = {
  // Comments getters
  getTotalReleaseComments(state) {
    return state.totalReleaseComments;
  },
  getAllReleaseComments(state) {
    return state.allReleaseComments;
  },
  getTotalCommentCount(state) {
    return state.totalCommentCount;
  },
  // End
  getReleaseDetailErrorMessage(state) {
    return state.releaseDetailErrorMessage;
  },
  getReleaseDetail(state) {
    return state.releaseDetail;
  },
  getShareStatsCount(state) {
    return state.shareStatsCount;
  },
  getRefreshRelease(state) {
    return state.refreshRelease;
  },
  getLatestAndroidFeedback(state) {
    return state.latestAndroidFeedback;
  },
  getAttachmentsProgressList(state) {
    return state.attachmentsProgressList;
  },
  getFeedbackLoader(state) {
    return state.showLoader;
  },
  getTotalProgress(state) {
    if (state.attachmentsProgressList.length) {
      let progressList = state.attachmentsProgressList.map((x) =>
        parseInt(x.progress)
      );
      let sum = progressList.reduce((x, y) => x + y, 0);
      return sum;
    } else {
      return 0;
    }
  },
  getAttachmentsList(state) {
    return state.attachmentsList;
  },
  getAttachmentThumbnailList(state) {
    return state.attachmentThumbnailList;
  },
  getLatestIosFeedback(state) {
    return state.latestIosFeedback;
  },
  getLatestAndroidFeedbackCount(state) {
    return state.latestAndroidFeedbackCount;
  },
  getLatestIosFeedbackCount(state) {
    return state.latestIosFeedbackCount;
  },
  getViewsList(state) {
    return state.viewsList;
  },
  getTotalViewsList(state) {
    return state.totalViewsList;
  },
  getTotalViewsCount(state) {
    return state.totalViewsCount;
  },
  getInstallList(state) {
    return state.installList;
  },
  getTotalInstallList(state) {
    return state.totalInstallList;
  },
  getTotalInstallCount(state) {
    return state.totalInstallCount;
  },
  getReleaseInstallFeedback(state) {
    return state.releaseInstallFeedback;
  },
  getShareableLinks(state) {
    return state.shareableLinks;
  },
  getTotalShareableLinks(state) {
    return state.totalShareableLinks;
  },
  getTotalShareableLinksCount(state) {
    return state.totalShareableLinksCount;
  },
  getReleaseAutocomplete(state) {
    return state.releaseAutocomplete;
  },
  getReleaseInitialList(state) {
    return state.releaseInitialList;
  },
  getTotalReleaseInstallFeedback(state) {
    return state.totalReleaseInstallFeedback;
  },
  getTotalReleaseInstallFeedbackCount(state) {
    return state.totalReleaseInstallFeedbackCount;
  },
  getFeedbackPageMessage(state) {
    return state.feedbackPageMessage;
  },
  getReleases(state) {
    return state.releases;
  },
  getDataFromSocket(state) {
    return state.dataFromSocket;
  },
  getDataFromFeedbackSocket(state) {
    return state.dataFromFeedbackSocket;
  },
  getCommentAttachmentSocketData(state) {
    return state.commentAttachmentSocketData;
  },
  getActiveFeedbackId(state) {
    return state.activeFeedbackId;
  },
  getReloadReleasePage(state) {
    return state.reloadReleasePage;
  },
  getTotalReleasesData(state) {
    return state.totalReleasesData;
  },
  getReleasesMeta(state) {
    return state.releaseMeta;
  },
};

const actions = {
  [RELEASES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/core/app/${params.app_id}/releases?limit=${params.limit || 10}&from=${
          params.from || 0
        }`
      )
        .then(({ data }) => {
          let response = data.data;
          let releases = get(response[0], "data", []);
          let releaseMeta = response[0].meta;
          if (params.page > 1) {
            context.commit("setReleaseOtherData", {
              data: releases,
              page: params.page,
              reload: params.reload,
              meta: releaseMeta,
            });
          } else {
            context.commit("setReleaseInformation", {
              releases,
              releaseMeta,
            });
          }
          context.commit("setReleaseLoader", false);
          resolve(response);
        })
        .catch((error) => {
          context.commit("setReleaseLoader", false);
          reject(error);
        });
    });
  },
  [DELETE_RELEASE](context, { app_id, release_id }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/portal/app/${app_id}/release/${release_id}`)
        .then(({ data }) => {
          context.commit(REMOVE_RELEASE, release_id);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ARCHIVE_RELEASE](context, { app_id, release_id }) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/app/${app_id}/release/${release_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ARCHIVE_RELEASE_SCHEDULE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/portal/app/${payload.id}/release/settings`, {
        action: payload.action,
        value: payload.value,
        ids: payload.ids,
        method: payload.method,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ARCHIVE_TEAM_RELEASE_SCHEDULE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/team/settings`, {
        auto_archive_release: {
          days: payload.value,
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [MOVE_RELEASE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/portal/app/${payload.currentAppId}/move/release/${payload.release_id}`,
        {
          app_id: payload.app_id,
          mark_latest: payload.mark_latest,
          notify: payload.notify,
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_INSTALL_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/app/comment/${payload.uid}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SEND_RELEASE_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `portal/app/${payload.app_id}/release/${payload.release_id}/comment`,
        { message: payload.message, attachments: payload.attachments }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VALIDATE_TESTFLIGHT_LINK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/core/app/${payload.app_id}/share/validate-testflight-link`,
        { link: payload.link || "" }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_FEEDBACK_ATTACHMENT](context, payload) {
    return new Promise((resolve, reject) => {
      // app_id, feedback_id, session_id, last_id
      const url = getAPIFullURL("/core/session/attachments", payload);
      ApiService.get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_FEEDBACK_ATTACHMENT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/core/app/${payload.app_id}/comment/${payload.feedback_id}/attachment/${payload.id}`,
        { note: payload.note }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_FEEDBACK_ATTACHMENT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `/core/app/${payload.app_id}/comment/${payload.feedback_id}/attachment/${payload.attachment_id}`
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_RELEASE_INSTALL_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/core/app/${payload.app_id}/comment?lastID=${
          payload.lastId || ""
        }&releaseID=${payload.release_id || ""}&shareID=${
          payload.shareId || ""
        }&external=${payload.external || ""}&single=${payload.single || false}`
      )
        .then((response) => {
          if (payload.lastId) {
            context.commit(
              "setInstallReleasePaginatedData",
              response.data.results.data
            );
          } else {
            context.commit(
              "setInstallReleaseFeedback",
              response.data.results.data
            );
            context.commit(
              "setTotalInstallReleaseCount",
              response.data.results.meta.count
            );
            context.commit(
              "setFeedbackPageMessage",
              response.data.results.meta.message
            );
          }
          resolve(response.data.results);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_INDIVIDUAL_RELEASE_INSTALL_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/session/comment/${payload.feedback_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_FEEDBACK_INTERNAL_NOTES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/core/app/${payload.app_id}/comment/${payload.feedback_id}/internal-note`,
        { note: payload.note }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_FEEDBACK_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/core/app/${payload.app_id}/comment/${payload.feedback_id}/status`,
        { status: payload.status }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_FEEDBACK_DETAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/core/app/${payload.app_id}/comment/${payload.feedback_id}`,
        { rating: payload.rating, message: payload.message }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_INSTALL_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `/core/app/${payload.app_id}/comment/${payload.feedback_id}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_RELEASE_AUTOCOMPLETE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${params.app_id}/release/search?query=${
          params.search
        }&platform=${params.platform || ""}&destination=${params.destination}`
      )
        .then(({ data }) => {
          context.commit("setReleaseAutocomplete", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_RELEASE_INITIAL_LIST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${params.app_id}/release/search?platform=${params.platform}&destination=${params.destination}`
      )
        .then(({ data }) => {
          context.commit("setReleaseInitialList", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // [CHECK_SHARE_LINK](context, payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.get(`core/app/${payload.app_id}/share/check`)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
  [CREATE_SHAREABLE_LINK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`core/app/${payload.app_id}/share`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SHARE_LINK_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${payload.app_id}/share?lastID=${payload.lastId || ""}`,
        payload
      )
        .then(({ data }) => {
          if (payload.lastId) {
            context.commit("setShareableLinkPaginatedData", data.results.data);
          } else {
            context.commit("setShareableLink", data.results.data);
            context.commit("setShareableLinkCount", data.results.meta.count);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SHARE_LINK_STATS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/app/${payload.app_id}/share/stats`)
        .then(({ data }) => {
          context.commit("setShareStatsCount", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SHARE_CHART_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${payload.app_id}/share/chart?shareID=${
          payload.link_id
        }&action=${payload.type}&year=${payload.year || ""}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_APP_PUBLIC_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `portal/app/${payload.app_id}/settings/public-status`,
        payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_SHARE_LINK_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `core/app/${payload.app_id}/share/${payload.share_id}/status`,
        payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SHARE_VIEWS_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${payload.app_id}/share/views?shareID=${
          payload.link_id
        }&lastID=${payload.lastId || ""}`
      )
        .then(({ data }) => {
          if (payload.lastId) {
            context.commit("setViewsPaginatedData", data.results.data);
          } else {
            context.commit("setViewsList", data.results.data);
            context.commit("setTotalViewsCount", data.results.meta.count);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SHARE_INSTALL_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${payload.app_id}/installs?shareID=${
          payload.link_id
        }&lastID=${payload.lastId || ""}`
      )
        .then(({ data }) => {
          if (payload.lastId) {
            context.commit("setInstallPaginatedData", data.results.data);
          } else {
            context.commit("setInstallList", data.results.data);
            context.commit("setTotalInstallCount", data.results.meta.count);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_SHAREABLE_LINK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`core/app/${payload.app_id}/share/${payload.link_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_SHAREABLE_LINK](context, payload) {
    delete payload.settings.latest_android;
    delete payload.settings.latest_ios;
    delete payload.updated_at;
    delete payload.created_at;
    delete payload.uid;
    delete payload.status;
    delete payload.main;
    delete payload.expire_at;
    delete payload.stats;
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/app/${payload.app_id}/share/${payload.id}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_RELEASE](context, { app_id, release_id, note }) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/portal/app/${app_id}/release/${release_id}/notes`, {
        note,
      })
        .then(({ data }) => {
          context.commit(RELEASE_UPDATED, { release_id, note });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_BULK_RELEASES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/portal/app/${payload.app_id}/releases`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_RELEASE_INFO](context, { app_id, release_id }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/app/${app_id}/release/${release_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_RELEASE_MEMBER](context, { app_id, release_id }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/app/${app_id}/release/${release_id}/members`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setCommentAttachmentSocketData(state, payload) {
    state.commentAttachmentSocketData = payload;
  },
  setShareStatsCount(state, payload) {
    state.shareStatsCount = payload;
  },
  setReleaseRefresh(state, payload) {
    state.refreshRelease = payload;
  },
  setReleaseDetailErrorMessage(state, payload) {
    state.releaseDetailErrorMessage = payload;
  },
  setReleaseDetail(state, payload) {
    state.releaseDetail = payload;
  },
  // comments
  setAllReleaseComment(state, payload) {
    state.allReleaseComments = payload;
    state.totalReleaseComments = payload;
  },
  setTotalReleaseComment(state, payload) {
    state.totalReleaseComments = [...state.totalReleaseComments, ...payload];
    state.allReleaseComments = payload;
  },
  setTotalCommentCount(state, payload) {
    state.totalCommentCount = payload;
  },
  // End
  setActiveFeedbackId(state, payload) {
    state.activeFeedbackId = payload;
  },
  clearAttachmentsProgress(state) {
    state.attachmentsProgressList = [];
  },
  updateFeedbackProgressList(state, payload) {
    state.attachmentsList = state.attachmentsList.map((item) => {
      if (item.id === payload.id) {
        item.progress = payload.progress;
        item.uploadStatus = payload.uploadStatus;
        item.uploadingSize = payload.uploadingSize;
      }
      return item;
    });
  },
  setAttachmentsList(state, payload) {
    state.attachmentsList = [...payload];
  },
  setAttachmentsListId(state, payload) {
    state.attachmentsList = state.attachmentsList.map((item) => {
      item.commentID = payload;
      return item;
    });
  },
  setAttachmentThumbnailList(state, payload) {
    state.attachmentThumbnailList = [...payload];
  },
  clearAttachmentList(state) {
    state.attachmentThumbnailList = [];
  },
  setAttachmentsProgressList(state, payload) {
    state.attachmentsProgressList.push(payload);
  },
  updateAttachmentProgress(state, payload) {
    state.attachmentsProgressList = state.attachmentsProgressList.map(
      (progress) => {
        if (progress.type === payload.id) {
          progress.progress = payload.progress;
          progress.uploadingSize = payload.uploadSize;
          progress.status = payload.status;
        }
        return progress;
      }
    );
  },
  updateLatestReleaseFeedbackCount(state, payload) {
    if (payload.id) {
      if (state.releases && state.releases.length) {
        state.releases = state.releases.map((release) => {
          if (payload.id === release.id) {
            release.meta.total_feedback =
              release && release.meta && release.meta.total_feedback
                ? release.meta.total_feedback - 1
                : 0;
          }
          return release;
        });
      }
    }
  },
  increaseReleaseFeedbackCount(state, payload) {
    state.releases = state.releases.map((release) => {
      if (release && release.id === payload.id) {
        release.stats.comments = release.stats.comments + 1;
      }
      return release;
    });
  },
  updateReleaseStatus(state, data) {
    state.releases = state.releases.map((release) => {
      if (release && release.id === data.id) {
        release.status = data.status;
        release.url = data.url;
        release.archive_at = data.archive_at;
      } else {
        state.releases.push(data);
      }
      return release;
    });
  },
  updateShareListStatus(state, payload) {
    state.shareableLinks = state.shareableLinks.map((link) => {
      if (link.id === payload.id) {
        link.status = payload.status;
        link.settings.custom_message = payload.message;
      }
      return link;
    });
    state.totalShareableLinks = state.totalShareableLinks.map((link) => {
      if (link.id === payload.id) {
        link.status = payload.status;
        link.settings.custom_message = payload.message;
      }
      return link;
    });
  },
  setLatestAndroidFeedback(state, payload) {
    state.latestAndroidFeedback = payload;
  },
  setLatestIosFeedback(state, payload) {
    state.latestIosFeedback = payload;
  },
  setLatestAndroidFeedbackCount(state, payload) {
    state.latestAndroidFeedbackCount = payload;
  },
  setLatestIosFeedbackCount(state, payload) {
    state.latestIosFeedbackCount = payload;
  },
  setPreviousShareLinkData(state, data) {
    state.shareableLinks = [...data];
  },
  setShareableLinkPaginatedData(state, payload) {
    let sharableLinks = state.totalShareableLinks.length
      ? state.totalShareableLinks
      : state.shareableLinks;
    sharableLinks = [...sharableLinks, ...payload];
    state.totalShareableLinks = sharableLinks;
    state.shareableLinks = payload;
  },
  setShareableLink(state, payload) {
    state.shareableLinks = payload;
  },
  setViewsList(state, payload) {
    state.viewsList = payload;
    state.totalViewsList = payload;
  },
  setInstallList(state, payload) {
    state.installList = payload;
    state.totalInstallList = payload;
  },
  setInstallPaginatedData(state, payload) {
    let installList = state.totalInstallList.length
      ? state.totalInstallList
      : state.installList;
    installList = [...installList, ...payload];
    state.totalInstallList = installList;
    state.installList = payload;
  },
  setViewsPaginatedData(state, payload) {
    let viewsList = state.totalViewsList.length
      ? state.totalViewsList
      : state.viewsList;
    viewsList = [...viewsList, ...payload];
    state.totalViewsList = viewsList;
    state.viewsList = payload;
  },
  setTotalInstallCount(state, payload) {
    state.totalInstallCount = payload;
  },
  setTotalViewsCount(state, payload) {
    state.totalViewsCount = payload;
  },
  setPreviousInstallList(state, payload) {
    state.installList = payload;
  },
  setPreviousViewsList(state, payload) {
    state.viewsList = payload;
  },
  setShareableLinkCount(state, payload) {
    state.totalShareableLinksCount = payload || 0;
  },
  setPreviousData(state, payload) {
    state.shareableLinks = payload;
  },
  setShareLinkUpdatedData(state, payload) {
    state.shareableLinks = state.shareableLinks.map((share) => {
      if (share.id == !payload.id) {
        share = payload;
      }
      return share;
    });
    state.totalShareableLinks = state.totalShareableLinks.map((share) => {
      if (share.id == !payload.id) {
        share = payload;
      }
      return share;
    });
  },
  removeShareLinkDeletedData(state, payload) {
    state.shareableLinks = state.shareableLinks.filter(
      (share) => share.id !== payload.id
    );
    state.totalShareableLinks = state.totalShareableLinks.filter(
      (share) => share.id !== payload.id
    );
    state.totalShareableLinksCount = state.totalShareableLinksCount
      ? state.totalShareableLinksCount - 1
      : 0;
  },
  removeFeedbackDeleteData(state, payload) {
    state.releaseInstallFeedback = state.releaseInstallFeedback.filter(
      (share) => share.id !== payload.id
    );
    state.totalReleaseInstallFeedback =
      state.totalReleaseInstallFeedback.filter(
        (share) => share.id !== payload.id
      );
    state.totalReleaseInstallFeedbackCount =
      state.totalReleaseInstallFeedbackCount
        ? state.totalReleaseInstallFeedbackCount - 1
        : 0;
  },
  setReleaseAutocomplete(state, payload) {
    state.releaseAutocomplete = payload;
  },
  setReleaseInitialList(state, payload) {
    state.releaseInitialList = payload;
  },
  setTotalInstallReleaseCount(state, payload) {
    state.totalReleaseInstallFeedbackCount = payload;
  },
  setFeedbackPageMessage(stats, payload) {
    stats.feedbackPageMessage = payload;
  },
  setInstallReleasePaginatedData(state, data) {
    let feedback = state.totalReleaseInstallFeedback.length
      ? state.totalReleaseInstallFeedback
      : state.releaseInstallFeedback;
    feedback = [...feedback, ...data];
    state.releaseInstallFeedback = [...data];
    state.totalReleaseInstallFeedback = [...feedback];
  },
  setPreviousReleaseFeedback(state, data) {
    state.releaseInstallFeedback = [...data];
  },
  updateInstallFeedbackStatus(state, data) {
    state.releaseInstallFeedback = state.releaseInstallFeedback.map((x) => {
      if (x.id === data.id) {
        x.status = data.status;
      }
      return x;
    });
    state.totalReleaseInstallFeedback = state.totalReleaseInstallFeedback.map(
      (x) => {
        if (x.id === data.id) {
          x.status = data.status;
        }
        return x;
      }
    );
  },

  setInstallReleaseFeedback(state, data) {
    state.releaseInstallFeedback = data || [];
    state.totalReleaseInstallFeedback = data || [];
  },
  setDataFromSocket(state, payload) {
    state.dataFromSocket = payload || null;
  },
  setFeedbackLoader(state, payload) {
    state.showLoader = payload;
  },
  updateDataFromFeedbackSocket(state, payload) {
    state.showLoader = false;
    state.dataFromFeedbackSocket = state.dataFromFeedbackSocket.map((item) => {
      if (item.id === payload.id) {
        item.status = payload.status;
      }
      return item;
    });
  },
  setDataFromFeedbackSocket(state, payload) {
    if (state.activeFeedbackId === payload?.feedback_id) {
      state.dataFromFeedbackSocket.push(payload);
    }
  },
  clearFromFeedbackSocket(state) {
    state.dataFromFeedbackSocket = [];
  },
  setReloadReleasePage(state, payload) {
    state.reloadReleasePage = payload || null;
  },

  changeReleaseToArchive(state, payload) {
    state.releases = state.releases?.map((release) => {
      if (release.id === payload.releaseId) {
        if (payload.archiveAt) {
          release.archive_at = payload.archiveAt;
        } else {
          release.status = 3;
        }
      }
      return release;
    });
  },
  removeDeletedRelease(state, payload) {
    state.releases = state.releases.filter(
      (release) => release.id !== payload.release_id
    );
    if (!payload.socket) {
      const totalReleases = state.releaseMeta ? state.releaseMeta.total - 1 : 0;
      state.releaseMeta = { total: totalReleases };
    }
  },

  setReleaseLoader(state, payload) {
    state.releaseLoader = payload;
  },
  setReleaseInformation(state, { releases, releaseMeta }) {
    state.releases = releases || [];
    state.releaseMeta = releaseMeta || {};
  },
  setLatestRelease(state, payload) {
    state.releases = payload;
  },
  setReleaseOtherData(state, data) {
    state.releaseMeta = data.meta;
    let release = state.totalReleasesData.length
      ? state.totalReleasesData
      : state.releases;
    if (data.reload) {
      release = release.slice(0, (data.page - 1) * 10);
    }
    release = [...release, ...data.data];
    state.releases = [...data.data];
    state.totalReleasesData = [...release];
  },
  setPreviousRelease(state, data) {
    state.releases = [...data];
  },
  [REMOVE_RELEASE](state, release_id) {
    state.releases = state.releases.filter(
      (release) => release.id != release_id
    );
  },
  [RELEASE_UPDATED](state, { release_id, note }) {
    state.releases = state.releases.map((release) => {
      if (release.id == release_id) {
        return { ...release, note };
      } else {
        return release;
      }
    });
  },
  [CLEAR_RELEASE_DATA](state) {
    state.releases = [];
    state.latest_releases = [];
    state.totalReleasesData = [];
    state.releaseMeta = {};
    state.releaseMessage = "";
    state.releaseInfo = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
