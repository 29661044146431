export default new Proxy(process.env, {
  get(target, prop) {
    if (prop in target) {
      return target[prop];
    }

    if (target.NODE_ENV === "development") {
      throw new Error(`Missing environment variable: ${String(prop)}`);
    }

    return undefined;
  },
});
