import ApiService from "@/core/services/api.service";

// action types
export const GET_APPS = "apps";
export const SEARCH = "search";
export const CLEAR_APPS = "clearApps";
export const JOIN_APP = "joinApp";
export const CHECK_APP = "checkApp";

// mutation types
export const SET_APPS = "apps";
export const FILTER_APPS = "filterApps";
export const CLEAR_APPS_DATA = "clearAppsData";
export const SEARCH_APPS = "searchApps";
export const GET_MEMBER_AUTOCOMPLETE = "getMembersAutocomplete";
export const GET_MEMBER_INITIAL_LIST = "getMembersInitialList";

const state = {
  apps: [],
  totalApps: 0,
  filteredApps: [],
  appsLoading: null,
  searchAppsInitial: [],
  searchedApps: [],
  membersList: [],
  totalAppsCount: 0,
  membersInitialList: [],
};

const getters = {
  getApps(state) {
    return state.apps;
  },
  getFilteredApps(state) {
    return state.filteredApps;
  },
  getTotalAppsCount(state) {
    return state.totalAppsCount;
  },
  getSearchedApps(state) {
    return state.searchedApps;
  },
  getSearchAppsInitial(state) {
    return state.searchAppsInitial;
  },
  getMemberAutocomplete(state) {
    return state.membersList;
  },
  getMemberInitialList(state) {
    return state.membersInitialList;
  },
};

const actions = {
  [GET_MEMBER_AUTOCOMPLETE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/team/members/autocomplete?search=${params.search}&appID=${
          params.appID || ""
        }`
      )
        .then(({ data }) => {
          context.commit("setMemberAutocomplete", data.results.data);
          resolve(data.results);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_MEMBER_INITIAL_LIST](context, appId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/members/autocomplete?appID=${appId || ""}`)
        .then(({ data }) => {
          context.commit("setMemberInitialList", data.results.data);
          resolve(data.results);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [JOIN_APP](context, appId) {
    return new Promise((resolve, reject) => {
      ApiService.post(`core/app/${appId}/join`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEARCH_APPS](context, params) {
    return new Promise((resolve, reject) => {
      // test
      ApiService.post(`core/apps/search`, {
        query: params.query,
        platform: params.platform || "",
        member_id: params.member_id,
        active: params.active,
        integration_sub_id: params.integrationSubId,
        page: params.page,
      })
        .then(({ data }) => {
          if (params.query) {
            context.commit("setSearchedApps", data.data);
          } else {
            context.commit("setSearchAppsInitial", data.data);
          }
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_APPS](context, params) {
    return new Promise((resolve, reject) => {
      // the loader befor the apps fetched
      context.commit("onAppsLoading", true);
      ApiService.get(`/portal/apps?lastID=${params.lastId || ""}`)
        .then(({ data }) => {
          // Apps have been fetched successfully, stop the loader
          if (!params.lastId) {
            context.commit("setTotalAppCount", data.meta.total);
            context.commit(SET_APPS, data.data);
          }
          context.commit("onAppsLoading", false);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("onAppsLoading", false);
          context.commit(SET_APPS, "");
        });
    });
  },
  [CHECK_APP]() {
    return new Promise((resolve, reject) => {
      ApiService.post(`portal/app/check`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEARCH](context, searchText) {
    context.commit(FILTER_APPS, searchText);
  },
  [CLEAR_APPS](context) {
    context.commit(CLEAR_APPS_DATA);
  },
};

const mutations = {
  removeAppFromList(state, appId) {
    if (state.appsInitialList && state.appsInitialList.length) {
      state.appsInitialList = state.appsInitialList.filter(
        (item) => item.id !== appId
      );
    }
    if (state.appsList && state.appsList.length) {
      state.appsList = state.appsList.filter((item) => item.id !== appId);
    }
  },
  setTotalAppCount(state, payload) {
    state.totalAppsCount = payload;
  },
  addAppToAutocomplete(state, payload) {
    if (state.appsList && state.appsList.length) {
      state.appsInitialList.push(payload);
      state.appsList.push(payload);
    }
  },
  // when trying to get apps
  onAppsLoading(state, payload) {
    state.appsLoading = payload;
  },
  setSearchedApps(state, payload) {
    state.searchedApps = payload;
  },
  setSearchAppsInitial(state, payload) {
    state.searchAppsInitial = payload;
  },
  setMemberAutocomplete(state, payload) {
    state.membersList = payload;
  },
  setMemberInitialList(state, payload) {
    state.membersInitialList = payload;
  },
  setAppResponse(state, payload) {
    if (state.apps && state.apps.length) {
      state.apps = [...state.apps, ...payload];
    } else {
      state.apps = [...payload];
    }
  },
  clearAppResponse(state) {
    state.apps = [];
  },
  [SET_APPS](state, { apps = [] }) {
    if (!lastId) {
      state.filteredApps = [...apps];
      state.apps = [...apps];
    } else {
      state.filteredApps = [...state.apps, ...apps];
    }
  },
  [FILTER_APPS](state, searchText) {
    if (searchText)
      state.filteredApps = state.apps.filter(
        (app) => app.name.toLowerCase().indexOf(searchText) != -1
      );
    else state.filteredApps = state.apps.slice();
  },
  [CLEAR_APPS_DATA](state) {
    state.apps = [];
    state.totalApps = 0;
    state.filteredApps = [];
    state.appsInitialList = [];
    state.appsList = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
