import ApiService from "@/core/services/api.service";
import { get, isEmpty } from "@/core/services/helper.service";

// action types
export const GET_ROLES = "getRoles";
export const SET_ROLES = "setRoles";
export const SET_PLANS = "setPlans";
export const GET_PLANS = "getPlans";
export const GET_TRANSACTION_HISTORY = "getTransactionHistory";
export const CHANGE_PLANS = "changePlans";
export const ADD_ADDONS = "addAddons";
export const REMOVE_ADDONS = "removeAddons";
export const START_FREE_TRIAL = "startFreeTrial";
export const UPGRADE_SUMMARY = "upgradeSummary";
export const PAUSE_SUBSCRIPTION = "pauseSubscription";
export const INIT_CANCEL = "initCancel";
export const CLAIM_OFFER = "claimOffer";
export const MAKE_A_DEAL = "makeADetail";
export const RESUME_PLAN = "resumePlan";
export const CANCEL_PLANS = "cancelPlans";
export const GET_MEMBER_DEVICE_LIST = "getMemberDeviceList";
export const GET_MEMBER_APPS_LIST = "getMemberAppsList";
export const CLEAR_PLANS_DATA = "ClearPlansData";
const state = {
  roles: [],
  plans: [],
  taxInformation: {
    tax: {},
    country: null,
  },
  paymentProcessing: false,
  transactionList: [],
  totalTransactionList: [],
  couponAppliedPrice: {},
  totalTransactionCount: 0,
  // StorageService.getItem("roles") || [],
};

const getters = {
  getRoles(state) {
    return state.roles;
  },
  getCouponAppliedPrice(state) {
    return state.couponAppliedPrice;
  },
  getPlansDetail(state) {
    return state.plans;
  },
  getTotalTransactionCount(state) {
    return state.totalTransactionCount;
  },
  getTotalTransactionList(state) {
    return state.totalTransactionList;
  },
  getTransactionList(state) {
    return state.transactionList;
  },
  getTaxInformation(state) {
    return state.taxInformation.tax;
  },
  getUserCountry(state) {
    return state.taxInformation.country;
  },
  getPostCode(state) {
    return state.taxInformation.postcode;
  },
  getPaymentProcessing(state) {
    return state.paymentProcessing;
  },
};

const actions = {
  [GET_PLANS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/team/plan`)
        .then(async ({ data }) => {
          await context.commit(SET_PLANS, data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_TRANSACTION_HISTORY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/plan/transactions?lastID=${params.lastId}`)
        .then(async ({ data }) => {
          if (params.lastId) {
            context.commit("setTransactionPaginatedData", data.results[0].data);
          } else {
            context.commit("setTransactionList", data.results[0].data);
            context.commit(
              "setTotalTransactionCount",
              get(data.results[0], "meta.count", 0)
            );
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHANGE_PLANS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/team/plan`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PAUSE_SUBSCRIPTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/pause`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [INIT_CANCEL]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/team/plan/cancel`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CLAIM_OFFER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/offer`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [MAKE_A_DEAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/deal`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RESUME_PLAN]() {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/resume`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_ADDONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/addons`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [REMOVE_ADDONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/core/team/plan/addon`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [START_FREE_TRIAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/trial`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPGRADE_SUMMARY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/upgrade-summary`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CANCEL_PLANS](context, payload) {
    return new Promise((reslove, reject) => {
      ApiService.delete(`/core/team/plan`, { ...payload })
        .then((response) => {
          reslove(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_MEMBER_DEVICE_LIST](_, memberId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/member/${memberId}/devices`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_MEMBER_APPS_LIST](_, memberId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/member/${memberId}/apps`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_ROLES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/team/roles`)
        .then(({ data }) => {
          context.commit(SET_ROLES, data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_ROLES](state, roles) {
    state.roles = [...roles];
  },
  [SET_PLANS](state, roles) {
    state.plans = roles;
  },
  setTaxInformation(state, payload) {
    state.taxInformation = payload;
  },
  setTransactionPaginatedData(state, payload) {
    let transaction = state.totalTransactionList.length
      ? state.totalTransactionList
      : state.transactionList;
    transaction = [...transaction, ...payload];
    state.transactionList = [...payload];
    state.totalTransactionList = [...transaction];
  },
  setPreviousTransactionData(state, data) {
    state.transactionList = [...data];
  },
  setTotalTransactionCount(state, payload) {
    state.totalTransactionCount = payload;
  },
  setTransactionList(state, payload) {
    if (!isEmpty(payload)) {
      state.transactionList = payload || [];
      state.totalTransactionList = payload || [];
    }
  },
  setPaymentProcessing(state, payload) {
    state.paymentProcessing = payload;
  },
  setCouponPrice(state, payload) {
    state.couponAppliedPrice = payload;
  },
  [CLEAR_PLANS_DATA](state) {
    state.roles = [];
    state.plans = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
