import ApiService from "@/core/services/api.service.js";
import store from ".././index.js";
export const GET_INSTALL_RELEASE_INFO = "getInstallReleaseInfo";
export const GET_EXPRESS_RELEASE_INFO = "getExpressReleaseInfo";
export const GET_INSTALL_APP_INFO = "getInstallAppInfo";
export const GET_NEW_INSTALL_APP_INFO = "getNewInstallAppInfo";
export const VERIFY_INSTALL_LINK_PASSWORD = "verifyInstallLinkPassword";
export const GET_CURRENT_TIMESTAMP = "getCurrentTimestamp";

const actions = {
  [GET_INSTALL_APP_INFO](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `portal/install/${params.appId}`,
        params.body,
        params.isExpress
          ? {
              headers: {
                common: {
                  "Express-Token": store.getters.expressReleaseToken,
                },
              },
            }
          : null
      )
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_CURRENT_TIMESTAMP]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/timestamp`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_NEW_INSTALL_APP_INFO](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `portal/install/app/${params.id}`,
        params.token
          ? {
              headers: {
                "access-token": params.token ? params.token : "",
                common: {
                  "access-token": params.token ? params.token : "",
                },
              },
            }
          : {}
      )
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_INSTALL_LINK_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`portal/install/app/${payload.id}/auth`, {
        password: payload.password,
      })
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_INSTALL_RELEASE_INFO](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`portal/install/release/${params.id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_EXPRESS_RELEASE_INFO](context, releaseId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`portal/install/express/${releaseId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default { actions };
