// import { get } from "lodash";
import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_NOTIFICATIONS_SETTINGS = "updateNotificationSetting";
export const GET_NOTIFICATIONS_SETTINGS = "getNotificationSetting";
export const CREATE_API_TOKEN = "createApiToken";
export const GET_API_TOKEN_LIST = "getAPiTokenList";
export const DELETE_API_TOKEN = "deleteApiToken";
export const UPDATE_API_TOKEN = "updateApiToken";
export const CLEAR_TOKEN_LIST = "updateApiToken";

// mutation types

const state = {
  tokenList: [],
};

const getters = {
  getTokenList(state) {
    return state.tokenList;
  },
};

const actions = {
  [GET_API_TOKEN_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/settings/api-tokens?current_team=${payload.current_team}`
      )
        .then(({ data }) => {
          resolve({ data });
          context.commit("setTokenList", data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_API_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`core/settings/api-token`, payload)
        .then((data) => {
          resolve({ data });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_API_TOKEN](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`core/settings/api-token/${id}`)
        .then((data) => {
          resolve({ data });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_API_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`core/settings/api-token/${payload.id}`, payload)
        .then((data) => {
          resolve({ data });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_NOTIFICATIONS_SETTINGS](context, uuid) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/settings/notification/${uuid}`)
        .then((data) => {
          resolve({ data });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_NOTIFICATIONS_SETTINGS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("core/settings/notification", payload)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setTokenList(state, payload) {
    state.tokenList = payload;
  },
  addNewToken(state, payload) {
    let list = [];
    list = [payload, ...state.tokenList];
    state.tokenList = list;
  },
  removeTokenList(state, id) {
    state.tokenList = state.tokenList.filter((token) => token.id !== id);
  },
  updateTokenList(state, payload) {
    let list = [];
    list = state.tokenList.map((token) => {
      if (token.id === payload.id) {
        token.name = payload.name;
      }
      return token;
    });
    state.tokenList = list;
  },
  [CLEAR_TOKEN_LIST](state) {
    state.tokenList = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
