import ApiService from "@/core/services/api.service";

// action types

export const SUBMIT_SUBSCRIBERS = "submitSubscribers";
export const GET_SUBSCRIBERS_LIST = "getSubscribersList";
export const UPDATE_SUBSCRIBERS_STATUS = "updateSubscriberStatus";
export const DELETE_SUBSCRIBERS = "deleteSubscribers";
export const UPDATE_SUBSCRIBERS_NOTE = "updateSubscriberNotes";

const state = {
  subscribersList: [],
  totalSubscribersList: [],
  totalSubscribersCount: "",
};

const getters = {
  getSubscribersList(state) {
    return state.subscribersList;
  },
  getTotalSubscribersList(state) {
    return state.totalSubscribersList;
  },
  getTotalSubscribersCount(state) {
    return state.totalSubscribersCount;
  },
};

const actions = {
  [SUBMIT_SUBSCRIBERS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/app/subscribe/${payload.uid}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_SUBSCRIBERS_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/core/app/${payload.app_id}/subscribe?lastID=${payload.lastId ||
          ""}&shareID=${payload.shareId || ""}`
      )
        .then((response) => {
          if (payload.lastId) {
            context.commit(
              "setSubscribersPaginatedData",
              response.data.results.data
            );
          } else {
            context.commit("setSubscribersList", response.data.results.data);
            context.commit(
              "setTotalSubscribersCount",
              response.data.results.meta.count
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_SUBSCRIBERS_NOTE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/core/app/${payload.app_id}/subscribe/${payload.subscribe_id}/internal-note`,
        { note: payload.note }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_SUBSCRIBERS_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/core/app/${payload.app_id}/subscribe/${payload.subscribe_id}/status`,
        { status: payload.status }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_SUBSCRIBERS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `/core/app/${payload.app_id}/subscribe/${payload.subscribe_id}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setTotalSubscribersCount(state, payload) {
    state.totalSubscribersCount = payload;
  },
  setSubscribersPaginatedData(state, data) {
    let subscribers = state.totalSubscribersList.length
      ? state.totalSubscribersList
      : state.subscribersList;
    subscribers = [...subscribers, ...data];
    state.subscribersList = [...data];
    state.totalSubscribersList = [...subscribers];
  },
  setPreviousSubscriber(state, data) {
    state.subscribersList = [...data];
  },
  setSubscribersList(state, data) {
    state.subscribersList = [...data];
    state.totalSubscribersList = [...data];
  },
  updateSubscribersNote(state, data) {
    state.subscribersList = state.subscribersList.map((x) => {
      if (x.id === data.id) {
        x.note = data.note;
      }
      return x;
    });
    state.totalSubscribersList = state.totalSubscribersList.map((x) => {
      if (x.id === data.id) {
        x.note = data.note;
      }
      return x;
    });
  },
  removeDeletedSubscription(state, id) {
    state.subscribersList = state.subscribersList.filter(
      (subscriber) => subscriber.id !== id
    );
    state.totalSubscribersList = state.totalSubscribersList.filter(
      (subscriber) => subscriber.id !== id
    );
    state.totalSubscribersCount = state.totalSubscribersCount
      ? state.totalSubscribersCount - 1
      : 0;
  },
  updateSubscribersStatus(state, data) {
    state.subscribersList = state.subscribersList.map((x) => {
      if (x.id === data.id) {
        x.status = data.status;
      }
      return x;
    });
    state.totalSubscribersList = state.totalSubscribersList.map((x) => {
      if (x.id === data.id) {
        x.status = data.status;
      }
      return x;
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
